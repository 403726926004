import React, {useEffect, useState} from "react"
import { Col, Row, Button, Tooltip } from 'antd'
import classes from "./styles.module.css"
import PlaySvg from '../../../img/svg/play'
import { useTranslation } from "react-i18next"

interface Props{
  mp3: any
}

const PlayComponent: React.FC<Props> = ({ mp3 }: Props) => {
  
  const { t } = useTranslation()

  const [audioGr, setAudioGr ] = useState(new Audio(''))
  const [audioEn, setAudioEn ] = useState(new Audio(''))
  const [audioGrClick, setAudioGrClick ] = useState<boolean>(true)
  const [audioEnClick, setAudioEnClick ] = useState<boolean>(true)

  useEffect(() => {
    
    if (mp3) {
      setAudioGr(new Audio(mp3[1].url))
      setAudioEn(new Audio(mp3[0].url))
    }

  }, [mp3])

  const playAudioGr = () => {
    setAudioGrClick(!audioGrClick);
    audioGrClick ? audioGr.play() : audioGr.pause()
  }

  const playAudioEn = () => {
    setAudioEnClick(!audioEnClick);
    audioEnClick ? audioEn.play() : audioEn.pause()
  }

  return (
    <>
      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <div className={classes.eventTitle}>{t('Ακουστική Περιγραφή')}</div>
          
          <Tooltip placement="top" color='#1a4a68' title={ t('Κάντε κλικ για αναπαραγωγή')} >
            <Button
              icon={<PlaySvg width={37} height={37} fill="#fff" />}
              onClick={() => playAudioGr()}
              className={classes.btnPlay}>
              <span className={classes.btnPlayTitle}>{mp3 && mp3[1].caption}</span>
              <span className={classes.btnPlayFlag}><img src="/img/useful/gr.png" /></span>
              <span className={classes.btnPlayText}>Ελληνικά</span>
            </Button>
          </Tooltip>
          <br />
          <Tooltip placement="top" color='#1a4a68' title={ t('Κάντε κλικ για αναπαραγωγή')} >
            <Button
              icon={<PlaySvg width={37} height={37} fill="#fff" />}
              onClick={() => playAudioEn()}
              className={classes.btnPlay}>
              <span className={classes.btnPlayTitle}>{mp3 && mp3[0].caption}</span>
              <span className={classes.btnPlayFlag}><img src="/img/useful/gb.png" /></span>
              <span className={classes.btnPlayText}>English</span>  
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </>
  )
}

export default PlayComponent