import React from 'react'
import { Row, Col, Input, Button } from 'antd'
import classes from "./css/Search.module.css"
import { useTranslation } from "react-i18next"
import { SearchOutlined, HeartOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'


const Search: React.FC = () => {

  const { t } = useTranslation()

  const SearchInputs = () => {
    return (
      <Row justify="center">
        <Col xs={{ span:22 }} lg={{ span:16 }}>
          <div className={classes.line}>
            <Input placeholder={t("Εισάγετε ένα όρο αναζήτησης") || ''} prefix={<SearchOutlined />} className={classes.input} />
            <Button type="primary" className={classes.searchBtn}>{t("Αναζήτηση")}</Button>
          </div>
        </Col>
      </Row>
    )
  }


  return (
    <>
      <div className={classes.container}>
        <Row justify="center">
          <Col xs={24} sm={24} md={4} lg={4}>
            <div className={classes.center} >
              <hr className={classes.hr} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16}>
            <div className={classes.title}>
              {t('Search_Title')}
            </div>
          </Col>
          <Col lg={4}> </Col>
        </Row>

        <SearchInputs />

        <Row justify="center">
          <Col xs={{ span:22 }} lg={{ span:16 }}>
            <div className={classes.subTitle}>
            <HeartOutlined /> {t('Search_SubTitle')}
            </div>
          </Col>
        </Row>        
        <Row justify="center">
          <Col xs={{ span:22 }} lg={{ span:16 }}>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_48">{ t('Σαμαριά') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_430">{ t('Φάρος Χανίων') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_278">{ t('Λουτρό') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_444">{ t('Σαρακίνικο Γαύδου') }</Link></Button>
          </Col>
          <Col xs={{ span:22 }} lg={{ span:16 }}>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_358">{ t('Μπάλος') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_470">{ t('Αρχαιολογικό Μουσείο Χανίων') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_1">{ t('Λίμνη Κουρνά') }</Link></Button>
            <Button type="primary" className={classes.popularBtn}><Link to="/exploration/1_240">{ t('Παλαιοχώρα') }</Link></Button>
          </Col>
        </Row>
        
      </div>
    </>
  )
}

export default Search