import React from "react"
import Props from './svgProps'
    
const LaboratiriesSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.96 20">     
       
        <path fill={fill} d="M12.92,10.83H.42A.41.41,0,0,1,0,10.42V8.75a.42.42,0,0,1,.42-.42h12.5a.41.41,0,0,1,.29.13.38.38,0,0,1,.12.29v1.67a.41.41,0,0,1-.41.41ZM.83,10H12.5V9.17H.83Z"/>
        <path fill={fill} d="M11.67,20h-10a.42.42,0,0,1-.42-.42L.83,10.42A.42.42,0,0,1,1.25,10H12.08a.41.41,0,0,1,.42.42l-.42,9.16a.42.42,0,0,1-.12.3.4.4,0,0,1-.29.12Zm-9.59-.83h9.17l.42-8.34h-10Z"/>
        <path fill={fill} d="M8.33,8.75H7.5V2.22L6.67,1.11,5.83,2.22V8.75H5V2.08a.46.46,0,0,1,.08-.25L6.33.17h0A.42.42,0,0,1,6.67,0,.4.4,0,0,1,7,.17L8.25,1.83h0a.46.46,0,0,1,.08.25Z"/>
        <path fill={fill} d="M5.42,1.67h2.5V2.5H5.42Z"/>
        <path fill={fill} d="M6.25,2.08h.83V7.5H6.25Z"/>
        <path fill={fill} d="M12.5,8.75h-.83V2.5H10V8.75H9.17V2.08a.41.41,0,0,1,.41-.41h2.5a.42.42,0,0,1,.3.12.4.4,0,0,1,.12.29Z"/>
        <path fill={fill} d="M9.58,3.33h1.25v.84H9.58Z"/>
        <path fill={fill} d="M9.58,5h1.25v.83H9.58Z"/>
        <path fill={fill} d="M9.58,6.67h1.25V7.5H9.58Z"/>
        <path fill={fill} d="M2.5,5.83a1.67,1.67,0,0,1-1.18-.48A1.7,1.7,0,0,1,.83,4.17C.83,3.36,2,2.06,2.19,1.8h0a.43.43,0,0,1,.62,0C3,2.06,4.17,3.36,4.17,4.17a1.7,1.7,0,0,1-.49,1.18,1.67,1.67,0,0,1-1.18.48Zm0-3.1a3.7,3.7,0,0,0-.83,1.44.85.85,0,0,0,.41.72.84.84,0,0,0,.84,0,.85.85,0,0,0,.41-.72A3.77,3.77,0,0,0,2.5,2.73Z"/>
        <path fill={fill} d="M1.25,5h.83V8.75H1.25Z"/>
        <path fill={fill} d="M2.92,5h.83V8.75H2.92Z"/>
        <path fill={fill} d="M1.67,6.67H3.33V7.5H1.67Z"/>
        <path fill={fill} d="M2.92,17.5h.83v.83H2.92Z"/>
        <path fill={fill} d="M4.58,17.5h5.84v.83H4.58Z"/>
      </svg>
    )
}

export default LaboratiriesSvg 