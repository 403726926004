
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Spin } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useRoute } from '../../hooks/useRoute'
import { useParams, useNavigate } from 'react-router-dom'



import HelmetComponent from '../../components/Helmet'
// import ViewRoutePoisesComponent from './viewRoutePoises'
import ViewPointsComponent from '../Exploration/viewPoints'
import LiefletComponent from './Lieflet'

import RouteSvg from '../../img/svg/route'
import MapSvg from '../../img/svg/map'


const Routes: React.FC = () => {

  const { id } = useParams()
  const { isLoading, RouteData } = useRoute(id||'')
  const navigate = useNavigate();


  const { t } = useTranslation()

  const [route, setRoute] = useState<any>({})
  const [polyline, setPolyline] = useState<[number, number][][]>([])
  const [routePoises, setRoutePoises] = useState<[number, number][][]>([])
  const [showMap, setShowMap] = useState<boolean>(false)

  const [latitude, setLatitude] = useState<string>('')
  const [longitude, setLongitude] = useState<string>('')
  const [setPoisesMarkerExploration, PoisesMarkerExploration] = useState<any[]>([])
  
  useEffect(() => {
    if(RouteData)
      setRoute(RouteData)
  }, [RouteData])


  useEffect(() => {

    let polyline: any[] = []
    let addPois: any[] = []
    let RoutePoises: any[] = []

    if ( Object.keys(route).length !== 0 ) {

        route.route_coordinates.forEach((popyl: any, j: any, row: any): void => {
          if (j + 1 === row.length) {
            addPois.push({ id: route.pois.length + 1, latitude: parseFloat(popyl.latitude), longitude: parseFloat(popyl.longitude), name: 'Finish' })
          }
          polyline.push([parseFloat(popyl.latitude), parseFloat(popyl.longitude)])
        })

      route.pois.forEach((poi: any): void => {

          RoutePoises.push({
            main_id: route.id,
            id: poi.id,
            latitude: parseFloat(poi.latitude),
            longitude: parseFloat(poi.longitude),
            image: poi.main_image,
            title: poi.name,
            text: poi.portal_main_info,
            category: poi.categories[0].name,
            rating: poi.rating !== null ? poi.rating : 0,
            municipality: poi.extras._municipalityName.value,
          })
        })

      setPolyline(polyline)
      setRoutePoises(RoutePoises)
      setLatitude(route.lat)
      setLongitude(route.lng)

      // console.log(RoutePoises)
    }

  },[ route ])


  if (isLoading || routePoises.length < 1 ) {
    return (
      <Row justify="center" >
        <Col span={12} offset={6}>
          <div className={classes.loadingSpinner}> <Spin size="large" /> {t('Παρακαλώ περιμένετε')} ... </div>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <HelmetComponent title='Εξερεύνησε τις ομορφιές των Χανίων' descrition='Εξερεύνησε τις ομορφιές των Χανίων' />
     
      <div className={classes.container}>
        <Row justify="center">
          <Col span={20}>
            <div className={classes.title}>
              {route.title}
            </div>
            <div className={classes.subTitle}>
              {t(route.type)}
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={22}>
            <Button size='large' className={classes.BackBtn} onClick={() => navigate(-1)}>{t('Επιστροφή')}</Button>
          </Col>
        </Row>

      </div>

      {showMap &&
        <>  
        <div className={classes.container}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className={[classes.btn, classes.btnPadding].join(" ")}>
                <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <LiefletComponent
                latitude={latitude}
                longitude={longitude}
                pois={routePoises}
                polyline={polyline}
                iconImg={<RouteSvg width={25} height={25} fill="#275674" />}
              />
            </Col>
          </Row>
        </div>
        </>
      } 
      
      <div className={classes.checkBoxesContainerPoint}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            {!showMap &&
              <div className={classes.btn}><Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button></div>
            }
          </Col>
        </Row>
      </div>

      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24}>
          <ViewPointsComponent
              lg={18}
              xs={23}
              array={routePoises}
              total={routePoises.length}
              isAddOrDelete={true}
              setPoisesMarkerExploration={PoisesMarkerExploration}
              onClickAddRemove={ ()=> ''} />
        </Col>
      </Row>
    </>
  )
}

export default Routes
