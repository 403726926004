import { configureStore } from '@reduxjs/toolkit'

import Category from './mapPage/category'
import Event from './mapPage/event'
import Route from './mapPage/route'

export const store = configureStore({
  reducer: {
    Categories: Category,
    Events: Event,
    Routes: Route
  },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch