import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { axiosRoutes } from "../services/routes"


export const useRoute = (id: string) => {

  const [RouteData, setRouteData] = useState({})

  const { isLoading, isFetching, error } = useQuery(['route', id], () => axiosRoutes.getRoute(id), {

    onSuccess: (data) => {

       let route = {
          id: data.id,
          image: data.main_image,
          municipality: data.area_path[0][0],
          title: data.name,
          lat: data.pois[0].latitude,
          lng: data.pois[0].longitude,
          type: data.extras._route_type.value[0],
          pois: data.pois,
          route_coordinates: data.route_coordinates
        }

      setRouteData(route)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, RouteData }
}

export const useRoutes = () => {
  
  const [RoutesData, setRoutesData] = useState()

  const { isLoading, isFetching, error } = useQuery(['routes'], () => axiosRoutes.getRoutes(), {

    onSuccess: (data) => {

      setRoutesData(data) 
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, RoutesData }
}
