import React from "react"
import { Col, Row } from 'antd'
import classes from "./styles.module.css"

import GlobusSvg from '../../img/svg/globus'
import EventsSvg from '../../img/svg/events'
import TheaterSvg from '../../img/svg/theater'
import MovieSvg from '../../img/svg/movie'
import DanceSvg from '../../img/svg/dance'
import ConcertSvg from '../../img/svg/concert'
import ConcertsSvg from '../../img/svg/concerts'
import LaboratoriesSvg from '../../img/svg/laboratories'
import ExhibitionSvg from '../../img/svg/exhibition'
import ConferencesSVG from '../../img/svg/conferences'


// LEAFLET
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { EventsProps } from "../../hooks/props/event"
import point from "../../img/point.svg"


let SVG_Point = new Icon({
  iconUrl: point,
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})


interface Props{
  setPoisesMarkerExploration:any
}

const LiefletComponent: React.FC<Props> = ({ setPoisesMarkerExploration }:Props ) => {

  const MarkersPois = () => {
    return <>
      {
        setPoisesMarkerExploration.map((event: EventsProps) => {

          let textShort =  event.description ? `${event.description.substring(0, 160)}` : ' '
          textShort = textShort.slice(0, textShort.lastIndexOf("&"))

          return (
            <Marker
              key={event.id}
              icon={SVG_Point}
              position={[parseFloat(event.lat), parseFloat(event.lng)]}
            >
              <Popup >
                <div className={classes.MarkerGpsInfoWindow}>
                  <img src={event.image !== '' ? event.image : '../img/defaultPoisImage.jpg'} alt={event.title} />
                  <div className={classes.PointCenter}>
                    <div className={classes.MarkerPointCenterLeft}>
                      {event.title}
                    </div>
                  </div>
                  <div className={classes.PointCenter}>
                    <p className={classes.MarkerPointCenterCenterP}>{textShort}...</p>
                  </div>
                  <div className={classes.MarkerPointCenterBottom}>
                    <div className={classes.MarkerPointCenterButtomLeft}>
                       <GlobusSvg width={34} height={28} fill="#275674"/>&nbsp;&nbsp;{ event.municipality}
                    </div>
                    <div className={classes.MarkerPointCenterButtomRight}>
                      {event.category_id == 18 ? <MovieSvg width={34} height={28} fill="#275674"/> :
                        event.category_id == 19 ? <LaboratoriesSvg width={34} height={28} fill="#275674"/> :
                          event.category_id == 23 ? <EventsSvg width={34} height={28} fill="#275674"/> :
                            event.category_id == 25 ? <ConcertsSvg width={34} height={28} fill="#275674"/> :
                              event.category_id == 26 ? <DanceSvg width={34} height={28} fill="#275674"/> :
                                event.category_id == 27 ? <ExhibitionSvg width={34} height={28} fill="#275674"/> :
                                  event.category_id == 28 ? <TheaterSvg width={34} height={28} fill="#275674"/> :
                                    event.category_id == 29 ? <ConcertSvg width={34} height={28} fill="#275674"/> : <ConferencesSVG width={34} height={28} fill="#275674"/>}
                     &nbsp;&nbsp;{ event.category_type}
                    </div>
                  </div>
                </div>
              </Popup>
            </Marker>
          )
        })
      }
    </>
  }


  return (
    <>
      <Row justify="center">
        <Col span={23}>

          <MapContainer
            key={JSON.stringify([36.09132683539974, 28.08729093999769])} // To center every time
            center={[35.32169259709458, 24.103565475133887]}
            zoom={10}
            tap={false}
            style={{ height: '800px', width: '100%', zIndex: '0' }}
            zoomControl={false}
            scrollWheelZoom={false}
          >
        
            <ZoomControl position='bottomright' />
            <ScaleControl position="bottomright" metric imperial={false} />
            <MarkersPois />
            <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />

          </MapContainer>

        </Col>
      </Row>
    </>
  )
}

export default LiefletComponent