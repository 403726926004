export const AboutProjectData = [{
'gr':{
  id: 1,
  title: 'Σχετικά με το έργο',
  text:`
  <p>O παρόν δικτυακός τόπος, explorechania.gr, αναπτύχθηκε στο πλαίσιο του έργου «Αξιοποίηση καινοτόμων εφαρμογών ΤΠΕ για την ανάδειξη του τουριστικού 
  και πολιτιστικού πλούτου των Χανίων» του Δήμου Χανίων».  Χρηματοδοτήθηκε από το ΕΠ «Κρήτη» 2014-2020 (MIS :5030576, ΣΑΕΠ 002/1: 2019ΕΠ00210023) από την 
  Ευρωπαϊκή Ένωση - Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ) και από εθνικούς πόρους .</p>

  <p>Το έργο αυτό με δικαιούχο τον Δήμο Χανίων, βασίζεται στη διαδημοτική συνεργασία όλων των Δήμων της Περιφερειακή Ενότητας (Π.Ε.) Χανίων και της Εφορείας 
  Αρχαιοτήτων Χανίων.</p>
  
  <p>Στοχεύει στην από κοινού ολοκληρωμένη προβολή και ανάδειξη του πολιτιστικού και τουριστικού αποθέματος των επτά (7) Δήμων της Π.Ε Χανίων 
  (Αποκορώνου, Γαύδου, Καντάνου - Σελίνου, Κισσάμου, Πλατανιά, Σφακίων και Χανίων), μέσα από ένα σύνολο καινοτόμων και συνδυαστικών ψηφιακών δράσεων, 
  που παρέχουν νέες δυνατότητες προβολής του πολιτιστικού και τουριστικού περιεχομένου, στους κατοίκους και επισκέπτες των Χανίων.</p>
  
  <p>Δείτε περισσότερες πληροφορίες πλαίσιο για το έργο εδώ: 
  <a href="https://www.chania.gr/enimerosi/erga-espa/ris-app-TPE-chania.html" target="_blank">«Αξιοποίηση καινοτόμων εφαρμογών ΤΠΕ για την ανάδειξη του τουριστικού και πολιτιστικού πλούτου των Χανίων» του Δήμου Χανίων</a></p>
  
  `
},
'en':{
  id: 2,
  title: 'About Project',
  text:`
  <p>This website, explorechania.gr, was developed in the framework of the project "Utilization of innovative ICT applications to highlight the touristic and 
  cultural wealth of Chania" of the Municipality of Chania. It was financed by the OP "Crete" 2014-2020 (MIS:5030576, SAEP 002/1: 2019ΕΠ00210023) from the 
  European Union - European Regional Development Fund (ERDF) and from national resources.</p>

  <p>This project, with the Municipality of Chania as beneficiary, is based on the inter-municipal cooperation of all the Municipalities of the Regional Unit 
  (PE) of Chania and the Ephorate of Antiquities of Chania.</p>
  
  <p>It aims at the joint comprehensive promotion and promotion of the cultural and touristic stock of the seven (7) Municipalities of the Province of Chania 
  (Apokoronou, Gavdos, Kantanou-Selinou, Kissamos, Platanias, Sfakion and Chania), through a set of innovative and combined digital actions, which provide 
  new opportunities to promote the cultural and touristic content, to the residents and visitors of Chania.</p>
  
  <p>See more background information about the project here: 
  <a href="https://www.chania.gr/enimerosi/erga-espa/ris-app-TPE-chania.html" target="_blank">"Use of innovative ICT applications to highlight the touristic and cultural wealth of Chania" of the Municipality of Chania</a></p>
  `
},
'bg':{
  id: 3,
  title: 'За проекта',
  text:`<p>GoInt e проект, финансиран от международната програма за трансгранично сътрудничество INTERREG V-A Гърция – България 2014-2020 г., фокусиран върху насърчаване на експортната дейност на компании от хранително-вкусовия сектор в съседните области между Гърция и България. GoInt се стреми да постигне това с три набора от резултати, фокусирани върху предоставянето на ИКТ инструменти и перспектива за износ на МСП в селскостопанската и хранително-вкусовата промишленост.</p>
  <p>Трима партньори с конституционен обхват за подпомагане на бизнеса обединяват силите си, за да разработят иновативна ИКТ платформа, да осигурят развитие на умения, да подкрепят износа в два избрани целеви пазара (Виетнам и Израел), както и да създадат пионерски „Работни групи за подкрепа на износа“, за да подкрепят предприятията в разрешаването на потенциални бизнес казуси, както и да подобрят бизнес климата.</p>
  <p>Крайната цел на проекта е да подсили факторите, оказващи дългосрочно влияние върху предприемаческия успех на агро-хранителната индустрия, като квалифицирана работна ръка, отговаряща за продажбите, достъп до европейска и национална подкрепа, електронен бизнес, агресивен маркетинг, достъп до чужди пазари; критични фактори, идентифицирани от проучването, проведено от Програмата за сътрудничество през 2015 г.</p>
  <p>Като първоначална стъпка, повече от 60 компании ще получат насоки чрез Работната група за подкрепа на износа (Export Support Working Groups) от България и Гърция. </p>
  <p>В основите си проекта залага успешното участие на 5 компании в изложението AgroMashov в Израел на 5-6 септември 2022 г., както и две мисии от приблизително 20 компании на външните пазари в Израел и Виетнам през септември и ноември.</p>
  <p>Разработват се и ИКТ решения с цел подпомагане на компаниите в действията им за привличане на нови клиенти от чужди пазари. Очаква се повече от 4000 компании в областта на транс-граничното сътрудничество да спечелят от актуализираните платформи TEAS и e-Instrument.</p>
  
  <h3>Планирани резултати</h3>
  <ul>
  <li style="font-weight:bold;padding-bottom:4px;">а. Услуги за подпомагане на бизнеса по отношение на експортна ориентация</li>
  - 10 технически срещи на работни групи за подкрепа на износа<br/>
  - 4 B2B срещи с посолства и търговски мисии<br/>
  - 2 мисии за целеви пазари (по една мисия във всеки целеви пазар)<br/>
  - Участие в един хранителен панаир (чрез покани за МСП)<br/>
  <li style="font-weight:bold;padding-bottom:4px;">b. Създаване на постоянен бизнес ИКТ инструмент</li>
  - Разработване на електронен инструмент за ИКТ в подкрепа на бизнеса<br/>
  - Актуализация на една онлайн платформа на службите по търговско-икономически въпроси<br/>
  - Разработване на една нова онлайн база данни за достъп до пазара (Market Access Database)<br/>
  <li style="font-weight:bold;padding-bottom:4px;">с. Развитие на човешките умения и капацитет</li>
  - 4 цикъла на обучение за целевите пазари<br/>
  - Менторинг на групи (10 казуса) за увеличаване на експортните възможности на МСП<br/>
  - Персонализирано консултиране (18 казуса) за промоция и позициониране на продукти<br/>
  </ul>

  <h3>Очаквани резултати </h3>
  <ul>
  <li style="font-weight:bold;padding-bottom:4px;">a. Осигуряване на услуги в подкрепа на агро-хранителната индустрия  </li>
  - 60 компании ще получат насоки от Работните групи в помощ на износа (ESWG)<br/>
  - 50 компании ще увеличат бизнес мрежата си чрез B2B срещи<br/>
  - 20 предприятия ще участват в търговски мисии на чужди пазари <br/>
  - 5 предприятия ще участват в изложение за храни и хранителни продукти <br/>
  <li style="font-weight:bold;padding-bottom:4px;">b. ИКТ решения</li>
  - 2500 компании ще могат да извлекат полза от електронния инструмент (the e-Instrument)<br/>
  <li style="font-weight:bold;padding-bottom:4px;">c. Развитие на човешките умения и капацитет</li>
  - 10 български компании ще участват в менторски сесии <br/>
  - 18 компании ще получат индивидуално консултиране <br/>
  - 100 експерта ще бъдат обучени <br/>
  </ul>
  `
  }}
]
