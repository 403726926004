
import React, {useState, useEffect} from "react"
import { Col, Row, Button } from 'antd'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useEventsUpcoming } from '../../../hooks/useEvent'
import { useNavigate } from "react-router-dom"
import EventComponent from '../../../components/Box/EventComponent'


const ThreeEventsComponent: React.FC = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { EventsUpcoming } = useEventsUpcoming()

  const [upcoming, setEventsUpcoming] = useState<any[]>([]);

  useEffect(() => {
    setEventsUpcoming(EventsUpcoming)
  }, [EventsUpcoming])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
  
  return (
    <>
      <div className={classes.title}>
        {t('Εκδηλώσεις στα Χανιά')}
      </div>
      <div className={classes.subTitle}>
        {t('Όλες οι εκδηλώσεις που συμβαίνουν αυτή την περίοδο')}
      </div>

      <Row justify="center">

        {upcoming.slice(0, 3).map((event: any) => {

          return (
            <Col xs={24} sm={24} md={8} lg={8} key={event.id}>
            
            <EventComponent 
                id_item={event.id_item}
                image={event.image}
                title={event.title}
                price={event.price}
                time_periods={event.time_periods}
                place={event.place}
                category_id={event.category_id}
                category_type={event.category_type}
              />
            </Col>
          )
        })}
      </Row>


      <div style={{ marginBottom: 60, marginTop: 30 }}>
        <Row justify="center">
          <Col span={11} xs={24} sm={24} md={11} lg={11}>
            <div className={classes.sectionButtonCenter}>
              <Button
                size='large'
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate( -1 )}
                className={classes.ReturnBtn}>&nbsp;&nbsp;{t('Επιστροφή')}
              </Button>
            </div>
          </Col>
          <div className={classes.space}></div>
          <Col span={11} xs={24} sm={24} md={11} lg={11}>
            <div className={classes.sectionButtonCenter}>
              <Button size='large' className={classes.HomeBtn} onClick={() => navigate(`/events`, { replace: true })}>
                {t('Όλες οι εκδηλώσεις')}&nbsp;&nbsp;<ArrowRightOutlined />
              </Button>
            </div>
          </Col>
        </Row>
      </div>


    </>
  )
}

export default ThreeEventsComponent