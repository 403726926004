import React from "react"
import { Row, Col, Button } from 'antd'
import classes from './styles.module.css'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface Props{
  title: string
  subTitle: string
}

const TitleAndSubtitleAndBtn: React.FC<Props> = ({ title, subTitle }: Props) => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <div className={classes.container}>
      <Row justify="center">
        <Col span={20}>
          <div className={classes.title}>
            { title }
          </div>
          <div className={classes.subTitle}>
            { subTitle }
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={19}>
          <Button size='large' className={classes.BackBtn} onClick={() => navigate(-1)}>{t('Επιστροφή')}</Button>
        </Col>
      </Row>
    </div>
  )
}

export default TitleAndSubtitleAndBtn
