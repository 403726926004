
import React from "react"
import Props from './svgProps'

const GlobusSvg = ({width, height, fill}:Props) => {

    return (
    <svg
        width={width}
        height={height}
        viewBox="0 0 22 23"
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2.37665C13.053 2.08902 12.0452 1.93396 11 1.93396C5.47715 1.93396 1 6.26332 1 11.6039C1 16.9444 5.47715 21.2738 11 21.2738C16.5228 21.2738 21 16.9444 21 11.6039C21 9.94516 20.5681 8.384 19.8071 7.01979M16 5.56017H16.005M9.50005 21.1657L9.50016 19.0351C9.50017 18.9197 9.54286 18.8081 9.62053 18.7205L12.1063 15.9161C12.3106 15.6856 12.2473 15.3322 11.9748 15.1816L9.11853 13.6033C9.04093 13.5605 8.97663 13.4982 8.93234 13.4232L7.07046 10.2681C6.97356 10.1039 6.78657 10.0094 6.59183 10.0261L1.06418 10.5022M20 5.80192C20 7.93814 18 9.66988 16 11.6039C14 9.66988 12 7.93814 12 5.80192C12 3.66571 13.7909 1.93396 16 1.93396C18.2091 1.93396 20 3.66571 20 5.80192ZM16.25 5.56017C16.25 5.69369 16.1381 5.80192 16 5.80192C15.8619 5.80192 15.75 5.69369 15.75 5.56017C15.75 5.42666 15.8619 5.31843 16 5.31843C16.1381 5.31843 16.25 5.42666 16.25 5.56017Z"
                stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    )
}

export default GlobusSvg