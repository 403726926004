import ky from 'ky'

export const axiosWeather = {

  async getWeather() {

    const data: any = await ky.get('https://api.openweathermap.org/data/2.5/weather?lat=35.51043168569523&lon=24.027344503472005&appid=8023e4182205203115c3cfe9fbcc1edf&units=metric&lang=el').json()
    return data
  },

  async getHours() {

    const data: any = await ky.get('https://worldtimeapi.org/api/timezone/Europe/Athens').json()
    return data
  }



}
