import React,{useState, useEffect} from "react"
import { useNavigate} from "react-router-dom"
import { Row, Col, Button, Radio, Divider, Anchor } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import TitleAndSubtitleAndBtn from '../../components/PageComponents/TitleAndSubtitleAndBtn'

const Useful: React.FC = () => {

    const { t } = useTranslation()

    const navigate = useNavigate();

    const navigateHome = () => {
        navigate('/');
    };
    const [sticky, setSticky] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.scrollY >= 1800) {
            setSticky(false)
          } else {
            setSticky(true)
          }
        });
      }, []);


    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;

    return (
        <>
            <HelmetComponent title="Χρήσιμα" descrition="Χρήσιμα page" />

            <TitleAndSubtitleAndBtn title={t('Χρήσιμα')} subTitle={''} />

            <div className={ sticky ? classes.sticky : classes.sticky2}>
                <div className={classes.RadioGroupContainer}>
                    <Row justify="center">
                        <Radio.Group>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 180}
                                    items={[
                                        {
                                            key: '1',
                                            href: '#TouristMaps',
                                            title: t('Τουριστικοί Χάρτες'),
                                        }
                                    ]}
                                />
                            </Radio.Button>

                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 180}
                                    items={[
                                        {
                                            key: '1',
                                            href: '#UsefulInfo',
                                            title: t('Χρήσιμες Πληροφορίες'),
                                        }
                                    ]}
                                />
                            </Radio.Button>

                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 180}
                                    items={[
                                        {
                                            key: '1',
                                            href: '#WeatherForecast',
                                            title: t('Πρόγνωση Καιρού'),
                                        }
                                    ]}
                                />
                            </Radio.Button>

                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 180}
                                    items={[
                                        {
                                            key: '1',
                                            href: '#LiveCams',
                                            title: t('Live Κάμερες'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 260 : 180}
                                    items={[
                                        {
                                            key: '1',
                                            href: '#Covid19',
                                            title: t('COVID-19'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                </div>
            </div>
            <Row justify="center" style={{zIndex:'1'}}>
                <div id="TouristMaps" className={classes.UsefulSection}>
                    <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Τουριστικοί Χάρτες')}</div>
                    <div className={classes.subTitleSection}> {t('Δείτε τη συλλογή χαρτών που θα σας φανούν χρήσιμοι σε διάφορες περιπτώσεις στην επίσκεψή σας στο Νομό Χανίων')}:</div>
                    <Divider />
                    <Row>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <div className={classes.sectionTitle}>
                                {t('Χάρτης Νομού Χανίων')}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10}>
                            <div className={classes.sectionTitle}>
                                <Button href='/img/useful/01.Chania_area_map_50_x_70-02_LowRes_GR_EN.jpg' target='_blank' download size='large'
                                    className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                    {t('Λήψη')}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <div className={classes.sectionTitle}>
                                {t('Χάρτης Πόλης Χανίων')}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10}>
                            <div className={classes.sectionTitle}>
                                <Button href='/img/useful/02_Chania_city_map_35_x_50-01_LowRes_EN.jpg' target='_blank' download size='large'
                                    className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                    {t('Λήψη')}</Button>
                            </div>

                        </Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <div className={classes.sectionTitle}>
                                {t('Χάρτης Αγίας Μαρίνας')}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10}>
                            <div className={classes.sectionTitle}>
                                <Button href='/img/useful/04_Agia_Marina_Stalos_map_35_x_ 50-02_low_res_EN.jpg' target='_blank' download size='large'
                                    className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                    {t('Λήψη')}</Button>
                            </div>
                        </Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <div className={classes.sectionTitle}>
                                {t('Χάρτης Ακρωτηρίου')}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10}>
                            <div className={classes.sectionTitle}>
                                <Button href='/img/useful/03_Akrotiri_map_35_x_50-01_LowRes_EN.jpg' target='_blank' download size='large'
                                    className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                    {t('Λήψη')}</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Row>

            <Row justify="center">
                <div id="UsefulInfo" className={classes.UsefulSection}>
                    <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Χρήσιμες Πληροφορίες')}</div>
                    <div className={classes.subTitleSection}> {t('Συλλογή τηλεφώνων και διευθύνσεων για Νοσοκομεία, Τουριστικές και Ταχυδρομικές Υπηρεσίες, Ταξί, Λιμεναρχεία, Λιμάνια και Προξενεία')}:</div>
                    <Divider />
                    <Row>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <div className={classes.sectionTitle}>
                                {t('Χρήσιμα τηλέφωνα - Δήμος Χανίων')}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10}>
                            <div className={classes.sectionTitle}>
                                <Button
                                    href='https://www.chania.gr/ta-chania-mou/xrhsthlefwna/useful-info.html'
                                    target='_blank'
                                    size='large'
                                    className={classes.BackBtn}>
                                    {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Row>

            <Row justify="center">
                <div id="WeatherForecast" className={classes.UsefulSection}>
                    <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Πρόγνωση Καιρού')}</div>
                    <div className={classes.subTitleSection}> {t('Η πρόγνωση του καιρού από την Επίσημη ιστοσελίδα της Εθνικής Μετερεωλογικής Υπηρεσίας (Ε.Μ.Υ)')}:</div>
                    <Divider />
                    <Row>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <div className={classes.sectionTitle}>
                                {t('Ο καιρός στα Χανιά')}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10}>
                            <div className={classes.sectionTitle}>
                                <Button
                                    href='https://www.chania.gr/ta-chania-mou/kairos/weather.html'
                                    target='_blank'
                                    size='large'
                                    className={classes.BackBtn}>
                                    {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Row>

            <Row justify="center">
                <div id="LiveCams" className={classes.UsefulSection}>
                    <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('Live Κάμερες')}</div>
                    <div className={classes.subTitleSection}> {t('Ζωντανή μετάδοση από διάφορα σημεία του Νομού')}:</div>
                    <Divider />
                    <Row>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <div className={classes.sectionTitle}>
                                {t('Live Κάμερες από το chania.gr')}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10}>
                            <div className={classes.sectionTitle}>
                                <Button
                                    href='https://www.chania.gr/ta-chania-mou/taXaniaLive/deite-live.html'
                                    target='_blank'
                                    size='large'
                                    className={classes.BackBtn}>
                                    {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                </Button>
                            </div>

                        </Col>
                    </Row>
                </div>
            </Row>
            <Row justify="center">
                <div id="Covid19" className={classes.UsefulSection}>
                    <div style={{ marginBottom: 10 }} className={classes.pointTitle}>{t('COVID-19')}</div>
                    {/* <div className={classes.subTitleSection}> {t('Ταξιδιωτικές πληροφορίες για τον COVID-19')}:</div> */}
                    <Divider />
                    <Row>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <div className={classes.sectionTitle}>
                                {t('Ταξιδιωτικές πληροφορίες για τον COVID-19')}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10}>
                            <div className={classes.sectionTitle}>
                                <Button
                                    href='https://mintour.gov.gr/covid-19/'
                                    target='_blank'
                                    size='large'
                                    className={classes.BackBtn}>
                                    {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                </Button>
                            </div>

                        </Col>
                    </Row>
                </div>
            </Row>


            <div style={{ marginBottom: 108 }}>
                <Row justify="center">
                    <Col span={11} xs={24} sm={24} md={11} lg={11}>
                        <div className={classes.sectionTitle}>
                            <Button
                                size='large'
                                icon={<ArrowLeftOutlined />}
                                onClick={() => {
                                    if (!window.location.hash)
                                        navigate(-1)
                                    else
                                        navigate(-2)
                                }
                                }
                                className={classes.ReturnBtn}>&nbsp;&nbsp;{t('Επιστροφή')}
                            </Button>

                        </div>
                    </Col>
                    <div className={classes.space}></div>
                    <Col span={11} xs={24} sm={24} md={11} lg={11}>
                        <div className={classes.sectionTitle}>
                            <Button
                                size='large'
                                className={classes.HomeBtn}
                                onClick={navigateHome}>
                                {t('Αρχική Σελίδα')}&nbsp;&nbsp;<ArrowRightOutlined />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Useful
