export const EventsData = [
{
    id: "1",
    item: "Συναυλίες",
},
{
    id: "2",
    item: "Θέατρο",
},
{
    id: "3",
    item: "Έκθεση",
},
{
    id: "4",
    item: "Κινηματογράφος",
},
{
    id: "5",
    item: "Χορός",
},
{
    id: "6",
    item: "Εκδηλώσεις",
},
{
    id: "7",
    item: "Ρεσιτάλ",
},
{
    id: "8",
    item: "Συνέδριο",
}
]