import ky from 'ky'
import {API, Header} from './configuration'

export const axiosPoints = {

  async getTopDestinations() {
    const data: any = await ky.get(`${API}/pois?is_highlight=1`, {headers: Header}).json()
    return data.success
  }

}