import React from "react"
import { Col, Row, Rate} from 'antd'
import classes from "./styles.module.css"
import GlobusSvg from '../../img/svg/globus'
import MapSvg from '../../img/svg/map'

import { HeartOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next"


// LEAFLET
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup, LayersControl, LayerGroup, useMapEvent ,useMap} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { GeneralProps } from "../../hooks/props/general"
import point from "../../img/point.svg"


let SVG_Point = new Icon({
  iconUrl: point,
  popupAnchor: [0, -20],
  iconSize: [26, 26]
})


interface Props{
  setPoisesMarkerExploration:any
}

const LiefletComponent: React.FC<Props> = ({ setPoisesMarkerExploration }:Props ) => {

  const { t } = useTranslation()

  const MarkersPois = () => {
    return <>
      {
        setPoisesMarkerExploration.map((pois: GeneralProps) => {

          let textShort = `${pois.text.substring(0, 160)}`
          textShort = textShort.slice(0, textShort.lastIndexOf("&"))

          return (
            <Marker
              key={pois.id}
              icon={SVG_Point}
              position={[parseFloat(pois.lat), parseFloat(pois.lng)]}
              eventHandlers={{
                // click: () => showChildrenDrawer(marker.id, marker.lantitude),
                // click: () => window.open(`../lindos/${marker.id}`, "_self"),
                // mouseover: (e) => e.target.openPopup(),
                // mouseout: (e) => e.target.closePopup()
              }}
            >
              <Popup >
                <div className={classes.MarkerGpsInfoWindow}>
                  <img src={pois.image !== '' ? pois.image : '../img/defaultPoisImage.jpg'} alt={pois.title} />
                  <div className={classes.MarkeraddToRoute}><HeartOutlined />&nbsp;&nbsp; {t('Προσθήκη στα Αγαπημένα')}</div>
                  <div className={classes.PointCenter}>
                    <div className={classes.MarkerPointCenterLeft}>
                      {pois.title}
                    </div>
                    <div className={classes.MarkerPointCenterRight}>
                      <Rate value={pois.rate} />
                    </div>
                  </div>
                  <div className={classes.PointCenter}>
                    <p className={classes.MarkerPointCenterCenterP}>{textShort}...</p>
                  </div>
                  <div className={classes.PointCenterBottom}>
                    <div className={classes.MarkerPointCenterButtomLeft}>
                      <div><GlobusSvg width={34} height={28} fill="#275674"/>&nbsp;&nbsp;{ pois.municipality}</div>
                    </div>
                    <div className={classes.PointCenterButtomRight}>
                      <div><MapSvg width={34} height={28} fill="#275674" />&nbsp;&nbsp;{ pois.category}</div>
                    </div>
                  </div>
                </div>
              </Popup>
            </Marker>
          )
        })
      }
    </>
  }


  return (
    <>
      <Row justify="center">
        <Col span={23}>

          <MapContainer
            key={JSON.stringify([36.09132683539974, 28.08729093999769])} // To center every time
            center={[35.32169259709458, 24.103565475133887]}
            zoom={10}
            tap={false}
            style={{ height: '800px', width: '100%', zIndex: '0' }}
            zoomControl={false}
            scrollWheelZoom={false}
            // doubleClickZoom: false, 
            // closePopupOnClick: false, 
            // dragging: false, 
            // zoomSnap: false, 
            // zoomDelta: false, 
            // trackResize: false,
            // touchZoom: false,
          >
        
            <ZoomControl position='bottomright' />
            <ScaleControl position="bottomright" metric imperial={false} />
            <MarkersPois />
            <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />

          </MapContainer>

        </Col>
      </Row>
    </>
  )
}

export default LiefletComponent