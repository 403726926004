
import ky from 'ky'
import {API, Header} from './configuration'

export const axiosRoutes = {

  async getRoute(id: string) {
    const data: any = await ky.get(`${API}/route?id=${id}`, {headers: Header}).json()
    return data.success
  },

  async getRoutes() {
    const data:any = await ky.get(`${API}/routes`, {headers: Header}).json()
    return data.success
  }

}