import iconA from "./A.svg"
import iconV from "./V.svg"
import iconG from "./G.svg"
import iconD from "./D.svg"
import iconE from "./E.svg"
import iconZ from "./Z.svg"
import iconH from "./H.svg"
import iconF from "./F.svg"
import iconI from "./I.svg"
import iconK from "./K.svg"
import iconL from "./L.svg"
import iconM from "./M.svg"
import iconN from "./N.svg"
import iconKS from "./KS.svg"
import iconO from "./O.svg"
// import iconP from "./P.svg"
// import iconR from "./R.svg"
// import icon18 from "./Σ.svg"
// import icon19 from "./Τ.svg"
// import icon20 from "./Υ.svg"
// import icon21 from "./Φ.svg"
// import icon22 from "./Χ.svg"
// import icon23 from "./Ψ.svg"
// import icon24 from "./Ω.svg"
import finish from "./finish.svg"
import iconUrl from "./map_marker.svg"

const SvgPointer = [ iconUrl, finish, iconA, iconV, iconG, iconD, iconE, iconZ, iconH, iconF, iconI, iconK, iconL, iconM, iconN, iconKS, iconO ]

export default SvgPointer;
