
import React from "react"
import Props from './svgProps'

const PlaySvg = ({width, height, fill}:Props) => {

    return (
    <svg
        width={width}
        height={height}
        viewBox="-8 -8 38 38"
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9451 28.5477C22.228 28.5477 28.1319 22.4786 28.1319 14.992C28.1319 7.50539 22.228 1.43628 14.9451 1.43628C7.66224 1.43628 1.7583 7.50539 1.7583 14.992C1.7583 22.4786 7.66224 28.5477 14.9451 28.5477Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.6484 10.8783C11.6484 10.2313 11.6484 9.9078 11.7799 9.7272C11.8946 9.56982 12.07 9.47135 12.2608 9.45735C12.4797 9.44127 12.7444 9.61621 13.2738 9.96607L19.4988 14.0798C19.9582 14.3834 20.1879 14.5352 20.2672 14.7282C20.3366 14.8969 20.3366 15.0872 20.2672 15.2558C20.1879 15.4488 19.9582 15.6006 19.4988 15.9042L13.2738 20.0179C12.7444 20.3678 12.4797 20.5427 12.2608 20.5267C12.07 20.5127 11.8946 20.4142 11.7799 20.2568C11.6484 20.0762 11.6484 19.7527 11.6484 19.1057V10.8783Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}

export default PlaySvg