import React from 'react'
import { Row, Col, Carousel } from 'antd'
import classes from "./css/Categories.module.css"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { settingsCarouselCategories } from '../../../components/ArrowsForCarousel/Categories'
import { CategoriesData } from '../Categories'
import { useDispatch } from 'react-redux'
import { History, Environment }  from '../../../redux/mapPage/category'


const Categories: React.FC = () => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

 
  return (
    <>
      <div className={classes.container}>
        
        <Row justify="center">
          <Col xs={24} sm={24} md={4} lg={4}>
            <div className={classes.center} >
              <hr className={classes.hr} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16}>
            <div className={classes.title}>
              {t('Κατηγορίες')}
            </div>
          </Col>
          <Col lg={4}> </Col>
        </Row>

        <Row justify="center">
          <Col xs={{ span:20 }} lg={{ span:16 }}>
            <div className={classes.subTitle}>
            {t('Κατηγορίες σημείων ενδιαφέροντος για να βρείτε ό,τι θέλετε')}
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={{ span:22 }} lg={{ span:20 }}>
           
          <Carousel {...settingsCarouselCategories}>
            {
              CategoriesData.map((categoria: any) => {
                return (
                  <div key={categoria.id}>
                    <div
                      className={classes.carouselslider}
                      onClick={() => categoria.type == "env" ? dispatch(Environment([categoria.typeId])) : dispatch(History([categoria.typeId]))}>
                      <Link to={`../explorations`} state={{ typeId: categoria.typeId }}>
                        <div className={classes.carouselimg}>
                          <img src={categoria.src} alt={categoria.title} />
                        </div>
                        <div className={classes.carouseldesc}>
                          <h3>{t(categoria.title)}</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
           </Carousel>

          </Col>
        </Row>
        
      </div>
    </>
  )
}

export default Categories