import React from "react"
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classes from "./Download.module.css"
import { ShakeOutlined } from '@ant-design/icons'

function Download() {

  const { t } = useTranslation()

  const year = new Date();

  return (
    
    <div className={classes.footerContainer}>
      {/* <Row justify="center">
        <Col span={2}>
        <div className={classes.icon}><ShakeOutlined /></div>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22}>
          <div className={classes.download}>{t('Κατέβασε την εφαρμογή Explore Chania')}</div>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22}>
          <div className={classes.add}>{t('Όλη η εμπειρία του Explore Chania στα χέρια σου!')}</div>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22}>
          <p>
            <img src="../img/iphoneWhite.png" alt="Iphone" className={classes.margin} /><br/>
            <img src="../img/androidWhite.png" alt="Android" className={classes.margin} />
          </p>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={20}>
          <div className={classes.hr}><hr/></div>
        </Col>
      </Row> */}
      
      <Row justify="center">
        <Col xs={{span:22}}  sm={{span:20}} md={{span:14}} lg={{span:14}}>
          <Row justify="center">
            <Col xs={24} sm={24} md={8} lg={8} className={classes.links} >
              <div><span>{t('Τουριστικοί Χάρτες')}</span></div>
              <Link to='/useful'><div>{t('Χρήσιμες Πληροφορίες')}</div></Link>
              <a href="https://www.chania.gr/ta-chania-mou/taXaniaLive/deite-live.html" target="_blank"><div>{t('Live Κάμερες')}</div></a>
              <div><span>{t('Sitemap')}</span></div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} className={classes.links}>
              <Link to='/explorations'><div>{t('Εξερεύνηση')}</div></Link>
              <Link to='/routes'><div>{t('Διαδρομές')}</div></Link>
              <Link to='/events'><div>{t('Εκδηλώσεις')}</div></Link>
              <Link to='/municipal_social_networks'><div>{t('Κοινωνικά Δίκτυα Δήμων')}</div></Link>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} className={classes.links}>
              <Link to='page/about_project' preventScrollReset={true} ><div>{t('Σχετικά με το Έργο')}</div></Link>
              <div><span>{t('Δήλωση Προσβασιμότητας')}</span></div>
              <div><span>{t('Όροι & Προυποθέσεις')}</span></div>
              <div><span>{t('Πολιτική Απορρήτου')}</span></div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={20}>
          <img src="../img/espa.jpg" alt="Espa" className={classes.espa} />
        </Col>
      </Row>

      <Row justify="center">
        <Col span={20}>
          <div className={classes.hr}><hr/></div>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={{span:22}}  sm={{span:22}} md={{span:20}} lg={{span:20}}>
          <Row justify="center">
            <Col xs={24} sm={24} md={12} lg={12} className={classes.year}>
              <div>© {year.getFullYear()} Explore Chania All rights reserved.</div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} className={classes.form}>
              <div><Link to='commentForm' className={classes.whiteColor}>{t('Φόρμα Σχολιασμού Περιεχομένου')} </Link></div>
            </Col>
          </Row>
        </Col>
      </Row>

    </div>
  )
}

export default Download