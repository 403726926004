import React from "react"
import { Row, Col, Divider  } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classes from "./Find.module.css"
import { HeartOutlined, ArrowRightOutlined } from '@ant-design/icons'

function Find() {

  const { t } = useTranslation()

  return (
    <>
      <div className={classes.footerContainer}>
        <Row justify="center">
          <Col span={2}>
          <div className={classes.icon}><HeartOutlined /></div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={22}>
            <div className={classes.find}>{t('Βρες τα Αγαπημένα σου σημεία!')}</div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={22}>
            <div className={classes.add}>{t('Προσθεσε προορισμους στα αγαπημενα σου και φτιαξε το δικο σου ταξιδι')}</div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={20}>
            <div className={classes.hr}><hr/></div>
          </Col>
        </Row>
       

        <Row justify="space-around">
          <Col xs={24} sm={24} md={8} lg={8} >
            <div className={classes.footerDiv}>
              <div className={classes.rounded}><span className={classes.number}>1</span></div>
              <div className={classes.title}>{t('Σημεία που σε ενδιαφέρουν')}</div>
              <div className={classes.body}>{t('Επίλεξε τα σημεία που σε ενδιαφέρουν πάνω στο χάρτη και πάτα “Προσθήκη στη διαδρομή”')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} >
          <div className={classes.footerDiv}>
              <div className={classes.rounded}><span className={classes.number}>2</span></div>
              <div className={classes.title}>{t('Κάνε είσοδο ή κατέβασε απευθείας')}</div>
              <div className={classes.body}>{t('Μπορείς να κατεβάσεις απευθείας το χάρτη στη συσκευή σου ή να εισέλθεις στο λογαριασμό σου και να αποθηκεύσεις εκεί τη διαδρομή')}</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} >
          <div className={classes.footerDiv}>
              <div className={classes.rounded}><span className={classes.number}>3</span></div>
              <div className={classes.title}>{t('Απόλαυσε τη διαδρομή')}</div>
              <div className={classes.body}>{t('Έχοντας το χάρτη με τα σημεία ενδιαφέροντος στο κινητό σου θα μπορείς να τα δεις offline στο κινητό σου στην επίσκεψή σου')}</div>
            </div>
          </Col>
        </Row>


      </div>
    </>
  )
}

export default Find