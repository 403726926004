import React, {useState, useEffect} from "react"
import { Button, Modal, Form, Input, notification } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useUserLogin } from '../../../hooks/useUser'
import { useAuth } from "../../../utils/auth"
import { useNavigate } from "react-router-dom"

interface Props{
    activeIndex: number
    clickModal: (one : number) => void,
}

const ModalLoginForm: React.FC<Props> = ({activeIndex, clickModal}) => {

    const [api, contextHolder] = notification.useNotification()
    const auth = useAuth()
    const navigate = useNavigate()

    // console.log(activeIndex)

    const [email, setEmail] = useState<string>('') 
    const [password, setPassword] = useState<string>('')

    const { status, error, User, refetch } = useUserLogin(email, password)

    const { t } = useTranslation()

    const [form] = Form.useForm()


    useEffect(() => {
        
        if (activeIndex == 1) {
            showModal()
            clickModal(0)
        }
       
    }, [activeIndex])


    const openNotification = () => {
        api.info({
        message: t('Είσοδος στο Λογαριασμό'),
        description: t('Καλώς ορίσατε ') + User?.user?.name + ' !',
        placement:'topRight'
        });
    }

    const showModal = () => {
        Modal.info({
            centered: true,
            closable: true,
            footer: null,
            title: t('Είσοδος στο Λογαριασμό'),
            width: 520,
            content: (
                <>
                    <br />
                    <Form
                        layout="vertical"
                        wrapperCol={{ span: 22 }}
                        form={form}
                        name="control-hooks"
                        onFinish={onFinish}
                        style={{ maxWidth: 500 }}
                    >
                        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
                            <Button className={classes.signUpBtn} htmlType="submit">{t('Σύνδεση')}</Button>
                            <Button className={classes.signUpBtn} htmlType="button" onClick={onReset}>{t('Επαναφορά')}</Button>
                            <Button type="link" htmlType="button" onClick={onFill} >Fill form</Button> 
                            
                        </Form.Item>
                    </Form>
                </>
            ),
            onOk() { },
        });
    };

    useEffect(() => {
        
        if (User?.user.name) {
            openNotification()
            setTimeout(() => {
             navigate('/favorites')
          }, 1500)
           
        }
            
    }, [User?.user.name])


    useEffect(() => {
        if (email !== '' && password !== '') {
            refetch()
        }
            
        auth.login(User?.user.id, User?.user.name, User?.user.username); 
      }, [email, password, User?.user.name])

    const onFinish = (values: any) => {
       
        setEmail(values.email)
        setPassword(values.password)
        // setTimeout(() => {
        //     openNotification()
        //   }, 2000)
        Modal.destroyAll()
    }

    // console.log('user', user)

    const onReset = () => {
        form.resetFields();
    }

    const onFill = () => {
        form.setFieldsValue({ email: 'sergios@dotsoft.gr', password: '12345678' });
    }



    return (
        <>
            {contextHolder}
            <Button size='large' className={classes.LoginBtn} onClick={showModal}> {t('Είσοδος στο Λογαριασμό')} </Button>
        </>
    )
}
export default ModalLoginForm
