
import React from "react"
import Props from './svgProps'

const EnvironmentSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 600 600"
        fill="none">
       
        <path fill={fill} fillRule='evenodd' d="M280,18.7c-52.8,0-102.7,113.4-102.7,233.3,0,125.5,58.6,140,102.7,140s102.7-14.5,102.7-140c0-119.9-49.9-233.3-102.7-233.3m0,392c-81.6,0-121.3-51.9-121.3-158.7C158.7,103.5,222.6,0,280,0S401.3,103.5,401.3,252c0,106.8-39.7,158.7-121.3,158.7" />
        <path fill={fill} fillRule='evenodd' d="M280,410.7a9.28,9.28,0,0,1-9.3-9.3v-168a9.3,9.3,0,1,1,18.6,0v168a9.28,9.28,0,0,1-9.3,9.3" />
        <path fill={fill} fillRule='evenodd' d="M280,317.3a9.31,9.31,0,0,1-6.6-2.7,9.42,9.42,0,0,1,0-13.2l37.3-37.3a9.24,9.24,0,0,1,13.2,0,9.42,9.42,0,0,1,0,13.2l-37.3,37.3a9.31,9.31,0,0,1-6.6,2.7" />
        <path fill={fill} fillRule='evenodd' d="M280,364a9.31,9.31,0,0,1-6.6-2.7L236.1,324a9.33,9.33,0,1,1,13.2-13.2l37.3,37.3a9.42,9.42,0,0,1,0,13.2A9.31,9.31,0,0,1,280,364" />
        <path fill={fill} fillRule='evenodd' d="M102.7,438.7a9.28,9.28,0,0,1-9.3-9.3V281a9.3,9.3,0,0,1,18.6,0V429.4a9.35,9.35,0,0,1-9.3,9.3" />
        <path fill={fill} fillRule='evenodd' d="M102.7,354.7a9.31,9.31,0,0,1-6.6-2.7,9.42,9.42,0,0,1,0-13.2l28-28A9.33,9.33,0,1,1,137.3,324l-28,28a9.48,9.48,0,0,1-6.6,2.7" />
        <path fill={fill} fillRule='evenodd' d="M102.7,392a9.31,9.31,0,0,1-6.6-2.7l-28-28a9.42,9.42,0,0,1,0-13.2,9.24,9.24,0,0,1,13.2,0l28,28a9.3,9.3,0,0,1-6.6,15.9" />
        <path fill={fill} fillRule='evenodd' d="M93.3,541.3H112v-103a145.85,145.85,0,0,1-18.7,0Zm28,18.7H84a9.28,9.28,0,0,1-9.3-9.3V428.1a9.63,9.63,0,0,1,3.2-7.1,9.14,9.14,0,0,1,7.4-2.2,134.35,134.35,0,0,0,34.7,0,9,9,0,0,1,7.4,2.2,9.63,9.63,0,0,1,3.2,7.1V550.7a9.22,9.22,0,0,1-9.3,9.3Z" />
        <path fill={fill} fillRule='evenodd' d="M457.3,438.7a9.28,9.28,0,0,1-9.3-9.3V281a9.3,9.3,0,0,1,18.6,0V429.4a9.22,9.22,0,0,1-9.3,9.3" />
        <path fill={fill} fillRule='evenodd' d="M457.3,354.7a9.31,9.31,0,0,1-6.6-2.7,9.42,9.42,0,0,1,0-13.2l28-28A9.33,9.33,0,0,1,491.9,324l-28,28a9.31,9.31,0,0,1-6.6,2.7" />
        <path fill={fill} fillRule='evenodd' d="M457.3,392a9.31,9.31,0,0,1-6.6-2.7l-28-28a9.42,9.42,0,0,1,0-13.2,9.24,9.24,0,0,1,13.2,0l28,28a9.42,9.42,0,0,1,0,13.2,9.31,9.31,0,0,1-6.6,2.7" />
        <path fill={fill} fillRule='evenodd' d="M448,541.3h18.7v-103a145.85,145.85,0,0,1-18.7,0ZM476,560H438.7a9.28,9.28,0,0,1-9.3-9.3V428.1a9.63,9.63,0,0,1,3.2-7.1,9.14,9.14,0,0,1,7.4-2.2,134.35,134.35,0,0,0,34.7,0,9,9,0,0,1,7.4,2.2,9.63,9.63,0,0,1,3.2,7.1V550.7A9.28,9.28,0,0,1,476,560Z" />
        <path fill={fill} fillRule='evenodd' d="M457.3,438.7c-53.1,0-85.9-26.5-97.6-78.7l18.2-4.1c9.8,43.7,35,64.1,79.4,64.1,39.1,0,84-12.7,84-111.6,0-94.4-41.6-187.1-84-187.1-22,0-45.1,25-61.8,67l-17.3-6.9c20-50,48.8-78.7,79.2-78.7,60.5,0,102.7,108.4,102.7,205.8-.1,87.6-33.7,130.2-102.8,130.2" />
        <path fill={fill} fillRule='evenodd' d="M102.7,438.7C33.6,438.7,0,396.1,0,308.4c0-97.3,42.2-205.8,102.7-205.8,30.3,0,59.2,28.7,79.2,78.7l-17.3,6.9c-16.8-41.9-39.9-67-61.8-67-42.4,0-84,92.7-84,187.1,0,98.9,44.9,111.5,84,111.5,44.4,0,69.6-20.4,79.4-64.1l18.2,4.1c-11.8,52.4-44.7,78.9-97.7,78.9" />
        <path fill={fill} fillRule='evenodd' d="M550.7,560H9.3a9.3,9.3,0,0,1,0-18.6H550.6a9.3,9.3,0,0,1,.1,18.6" />
        <path fill={fill} fillRule='evenodd' d="M270.7,541.3h18.7v-131a145.85,145.85,0,0,1-18.7,0Zm28,18.7H261.4a9.28,9.28,0,0,1-9.3-9.3V400.2a9.3,9.3,0,0,1,10.4-9.3,147.19,147.19,0,0,0,35.1,0,9.3,9.3,0,0,1,10.4,9.3V550.7a9.35,9.35,0,0,1-9.3,9.3Z" />
    
      </svg>
    )
}

export default EnvironmentSvg
