
import React, {useState, useEffect} from "react"
import { Col, Row, Pagination } from 'antd'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"

import EventComponent from '../../components/Box/EventComponent'

interface Props{
  array: any;
  total: number
  setEventsMarkerExploration: any
}

const ViewEventsComponent: React.FC<Props> = ({ array, total, setEventsMarkerExploration }:Props ) => {

  const { t } = useTranslation()

  const [Events, setEvents] = useState<any[]>(array);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(9);

  useEffect(() => {

    setEvents(array)
    setEventsMarkerExploration(currentPage * pageSize > array.length ? array.slice((currentPage - 1) * pageSize) : array.slice((currentPage - 1) * pageSize, currentPage * pageSize))

  }, [array])
  
  
  const onChange = (page: number, pageSize: number) => {

    setCurrentPage(page)
    setPageSize(pageSize)
    setEvents(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
    window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
    setEventsMarkerExploration(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
  }

  return (
    <>
      <Row justify="center">
        {Events.slice(0, pageSize).map((event: any) => {

          return (
            <Col xs={24} sm={24} md={8} lg={8} key={event.id}>
              <EventComponent 
                id_item={event.id_item}
                image={event.image}
                title={event.title}
                price={event.price}
                time_periods={event.time_periods}
                place={event.place}
                category_id={event.category_id}
                category_type={event.category_type}
              />
            </Col>
          )
        })}
      </Row>
      
      <Row justify="center">
        <Col span={23}>
          <Pagination
            onChange={onChange}
            className={classes.pagination}
            total={total}
            showSizeChanger ={true}
            showTotal={  (total, range) => t('Αποτελέσματα') +' '+ `${range[0]} - ${range[1]}`+ ' ' + t('από') +' '+ `${total}`}
            defaultPageSize={9}
            defaultCurrent={1}
          />
        </Col>
      </Row>

    </>
  )
}

export default ViewEventsComponent