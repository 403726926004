
import React from "react"
import { Row, Col, Checkbox } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"


interface ListData {
  id: string
  item: string
}

interface Props{
  list: ListData[]
  title: string
  icon: JSX.Element
  checkBoxLists?: (list: CheckboxValueType[]) => void
}

const CheckBoxGroup: React.FC<Props> = ({list, title, icon, checkBoxLists}) => {

  const { t } = useTranslation()

  return (
    <>
      <div className={classes.divider}>{icon}&nbsp;&nbsp;{title}</div>
      <Checkbox.Group style={{ width: '100%' }} onChange={(list: CheckboxValueType[]) => checkBoxLists?.( list )}>
        <Row>
          {list.map((data:any) => {
            return (
              <Col key={data.id} span={24} className={classes.checkboxGroup}>
                <Checkbox value={data.item}>{t(data.item)}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
    </>
  )

}
export default CheckBoxGroup