import React from "react"
import { Col, Row } from 'antd'
import classes from "./styles.module.css"
import SvgPointer from "./images/svg_pointers"

// LEAFLET
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup, Polyline} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

import { useNavigate  } from "react-router-dom"

const icon = new Icon({
  iconUrl: SvgPointer[0], 
  popupAnchor: [0, -20],
  iconSize: [40, 40]
});

interface Props{
  polyline: any
  pois: any
  iconImg: any
  setPoisesMarkerExploration:any
}

const LiefletComponent: React.FC<Props> = ({ polyline, pois, iconImg, setPoisesMarkerExploration }:Props ) => {

  const navigate = useNavigate();
 
  const MarkersPois = () => {
    return <>
    {
      pois.map((marker: any, index: number) => {
        return <div key={index} >
          {
            marker.map((po: any, i: number, row: any) => {

              let textShort = `${po.description.substring(0, 160)}`

              const icon = new Icon({
                iconUrl: row.length === i + 1 ? SvgPointer[1] : SvgPointer[i + 2],
                popupAnchor: [0, -20],
                iconSize: [40, 40]
              });

              return (
                <Marker
                  key={po.id}
                  position={[parseFloat(po.latitude), parseFloat(po.longitude)]}
                  icon={icon}
                >
                  <Popup>
                    <div className={classes.MarkerGpsInfoWindow}>
                      <img src={po.image !== '' ? po.image : '../img/defaultPoisImage.jpg'} alt={po.title} />
                      <div className={classes.gpsInfoWindow}>
                        <div className={classes.pointMapTitle}>{po.title}</div>
                      </div>
                      <div className={classes.PointCenter}>
                        <p className={classes.MarkerPointCenterCenterP}>{textShort}...</p>
                      </div>
                      <div className={classes.PointCenterBottom}>
                        <div className={classes.PointPopupCategory}>
                          <span className={classes.routeTypeIcon }>{iconImg}</span><span className={classes.routeTypeText }> {po.category}</span>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </Marker>
              )
            }
            )}
        </div>
      })
    }
  </>
  }


  return (
    <>
      <Row justify="center">
        <Col span={23}>

          <MapContainer
            key={JSON.stringify([36.09132683539974, 28.08729093999769])} // To center every time
            center={[35.32169259709458, 24.103565475133887]}
            zoom={9}
            tap={false}
            style={{ height: '800px', width: '100%', zIndex: '0' }}
            zoomControl={true}
            scrollWheelZoom={true}
          >
        
            <ZoomControl position='bottomright' />
            <ScaleControl position="bottomright" metric imperial={false} />
            <MarkersPois />
            <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
            {polyline.length && polyline.map((polyli: any, index: any) =>
   
              <Polyline
                key={index}
                eventHandlers={{
                  click: (e) => {
                    navigate(`/route/${pois[index][0].main_id}`, { replace: true });
                    // const closest = getSegment(e.latlng, e.sourceTarget);
                    // setResults([...results, closest]);
                  }
                }}
                color='green'
                weight={6}
                positions={polyline[index]}
              />
            )}
          </MapContainer>

        </Col>
      </Row>
    </>
  )
}

export default LiefletComponent