import React from "react"
import { Checkbox, Col, Row } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'

import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"

import {EventsData} from './events'
import EventsSvg from '../../img/svg/events'


// Redux
import { useDispatch } from 'react-redux'
import { EventsRedux }  from '../../redux/mapPage/event'


interface ListData {
  id: string
  value?: string
  item: string
}

interface Props{
  checkedId?: any[]
}

const CheckBoxEventComponent: React.FC<Props> = ({ ...props }) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const onChange = (list: CheckboxValueType[]) => {
    dispatch(EventsRedux(list))
  }
  
  return (
    <>
      <div className={classes.divider}><EventsSvg width={30} height={30} fill="#275674" />&nbsp;&nbsp;{t("Εκδηλώσεις")}</div>
      <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={props.checkedId && props.checkedId[0]}>
        <Row>
          {EventsData.map((data: ListData) => {
            return (
              <Col span={24} key={data.id} className={classes.checkboxGroup}>
                <Checkbox value={t( data.item)}>{t( data.item )}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
    </>
  )
}

export default CheckBoxEventComponent