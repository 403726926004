import React, {useState} from "react"
import { Col, Row } from 'antd'
import classes from "./styles.module.css"
// import SvgPointer from "./images/svg_pointers"

// LEAFLET
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import point from "../EventView/point.svg"


let SVG_Point = new Icon({
  iconUrl: point,
  popupAnchor: [0, -20],
  iconSize: [36, 36]
})

interface Props{
  image: string
  title: string
  latitude: string
  longitude: string
  venues: any
}

const LiefletComponent: React.FC<Props> = ({ venues, image, title, latitude, longitude }: Props) => {
  
  const MarkersPois = () => {

    return <>
      {
        venues.map((event: any) => {
          return (
            <Marker
              key={1}
              position={[event.coordinate_x, event.coordinate_y]}
              icon={SVG_Point}
            >
              <Popup>
                <div className={classes.MarkerGpsInfoWindow}>
                  <img src={image !== '' ? image : '../img/defaultPoisImage.jpg'} alt={title} />
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.MarkerGpsTitle}>{event.title}</div>
                  </div>
                </div>
              </Popup>
            </Marker>
          )
        })
      }
    </>
  }


  return (
    <>
      <Row justify="center">
        <Col span={23}>

          <MapContainer
            key={JSON.stringify([36.09132683539974, 28.08729093999769])} // To center every time
            center={[parseFloat(latitude), parseFloat(longitude)]}
            zoom={14}
            tap={false}
            style={{ height: '800px', width: '100%', zIndex: '0' }}
            zoomControl={false}
            scrollWheelZoom={false}
          >
            <ZoomControl position='bottomright' />
            <ScaleControl position="bottomright" metric imperial={false} />
            <MarkersPois />
            <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />

          </MapContainer>

        </Col>
      </Row>
    </>
  )
}

export default LiefletComponent