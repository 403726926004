import React, { useState, useEffect } from "react"
import { Row, Col, Select, Tooltip } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, UserOutlined, BellOutlined } from '@ant-design/icons';
import { useWeather, useHours } from '../../hooks/useWeather'
import { Link } from "react-router-dom"
import classes from "./Header.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import logo from "../../img/logo.png"
import Navbar from './Navbar'
import { Button } from 'antd';
import { useAuth } from "../../utils/auth"

const Header = () => {

  const { t } = useTranslation()
  const [Top, setTop] = useState(false)
  const auth = useAuth()
  const { weatherData } = useWeather()
  const { hours } = useHours()
 

  const handleChange = (value: string) => {
    i18n.changeLanguage(value)
  };


  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setTop(true);
      } else {
        setTop(false);
      }
    });
  }, []);

  
  return (
    <>
      
      <Row justify="center" style={{ borderBottom: '1px solid rgb(21, 150, 201)', backgroundColor: '#66A6BC'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }}>
        <div className={classes.containerTop}>
            <div style={{ justifySelf: 'end' }}><BellOutlined style={{fontSize: '20px'}} /></div>
              <div style={{ justifySelf: 'center' }}>{t('Επιλέξτε τη γλώσσα προτίμησης που θέλετε να εμφανίζεται η σελίδα')}</div>
              <div style={{ justifySelf: 'end' }}>
                <span className={classes.Language}>
                  <div className={classes.container3}>
                    <Select
                      defaultValue="gr"
                      size='large'
                      style={{ width: '148px' }}
                      onSelect={handleChange}
                      options={[
                        {
                          key: 1,
                          value: 'en',
                          label: (
                            <>
                              <img width="25" height="25" src="../../img/flags/en.svg" alt="English flag" /><span className={classes.LangImg}>English</span>
                            </>
                          )
                        },
                        {
                          key: 2,
                          value: 'gr',
                          label: (
                            <>
                              <img width="25" height="25" src="../../img/flags/gr.svg" alt="Greek flag" /><span className={classes.LangImg}>Ελληνικά</span>
                            </>
                          )
                        },
                        {
                          key: 3,
                          value: 'fr',
                          label: (
                            <>
                              <img width="25" height="25" src="../../img/flags/fr.svg" alt="France flag" /><span className={classes.LangImg}>Français</span>
                            </>
                          )
                        },
                        {
                          key: 4,
                          value: 'de',
                          label: (
                            <>
                              <img width="27" height="26" src="../../img/flags/de.svg" alt="Duitsland flag" /><span className={classes.LangImg}>Deutsch</span>
                            </>
                          )
                        },
                        {
                          key: 5,
                          value: 'ru',
                          label: (
                            <>
                              <img width="27" height="26" src="../../img/flags/ru.svg" alt="Russian flag" /><span className={classes.LangImg}>Русский</span>
                            </>
                          )
                        }
                      ]}
                    />
                  </div>
                </span>
              </div>
          </div>
        </Col>
      </Row>

   

      <Row justify="center" style={{ borderBottom: '3px solid rgb(21, 150, 201)'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 20 }}>
          <div className={classes.div}>
            <Row justify="center">
              <Col xs={24} sm={24} md={12} lg={12} >
              
                <span className={classes.Language}>
                  <div className={classes.container}>
                    <div className={classes.clockDiv} >
                      <Tooltip placement="top" color='#1a4a68' title={t('Τοπική ώρα στην Κρήτη')} >
                        <ClockCircleOutlined /> {hours}
                      </Tooltip>
                    </div>
                    <div className={classes.weatherDiv}>
                      &#9728;
                      <Tooltip placement="top" color='#1a4a68' title={t('Χαμηλότερη Θερμοκρασία')} >L {weatherData[0] && weatherData[0].degree_min} &#8451;&nbsp;  &#10072; &nbsp;</Tooltip>
                      <Tooltip placement="top" color='#1a4a68' title={t('Υψηλότερη Θερμοκρασία')} >H {weatherData[0] && weatherData[0].degree_max} &#8451;</Tooltip>
                    </div>
                    <div style={{ justifySelf: 'start' }}><EnvironmentOutlined />&nbsp; {t('Χανιά')}</div>
                  </div>
                </span>
             
              </Col>
              <Col xs={23} sm={23} md={12} lg={12}>
                
                <div style={{ justifySelf: 'end' }}>
                  <span className={classes.Language}>
                    {(auth.userData.name !== null && auth.userData.name !== undefined)
                    ?                 
                      <div className={classes.container3}>
                        <div style={{ justifySelf: 'end' }}>
                          <Link to="/favorites" className={classes.color}><HeartOutlined /> {t('Τα αγαπημένα μου')}</Link>&nbsp; &#10072;&nbsp;&nbsp;
                          <Link to="/account" className={classes.color}><UserOutlined /> {t('Ο Λογαριασμός μου')}</Link>
                        </div>
                      </div> 
                    :
                     <div className={classes.container3}>
                        <div className={classes.myAccountDiv} ><Link to="/login" className={classes.color}><UserOutlined  /> {t('Ο Λογαριασμός μου')}</Link></div>
                     </div>   
                    }
                    
                  </span>
                </div>
              </Col>

            </Row>
          </div>
        </Col>
      </Row>

      <div className={ !Top ? [classes.header, classes.flexContainer].join(" ") : [classes.header, classes.flexContainer, classes.BorderBottom].join(" ")  }>
        
        <div className={classes.containerBotton}>

          <div className={classes.app_logo}>
            <Link to="/">
              <img
                src={logo}
                alt="Logo Chania"
              />
            </Link>
          </div>
          
          <Navbar />

          <div>
            <span className={classes.searchButton} ><Link to="/search"><Button size="large" style={{ fontSize: '20px', height: '54px', width: '160px', color:'#275674' }} icon={<SearchOutlined />} >{ t('Αναζήτηση')}</Button></Link></span>
          </div>

        </div>
      </div>
    </>
  );
};

export default Header