
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Spin } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useEvent } from '../../../hooks/useEvent'
import { useParams, useNavigate } from 'react-router-dom'

import GlobusSvg from '../../../img/svg/globus'
import ClockSvg from '../../../img/svg/clock'
import MapSvg from '../../../img/svg/map'
import RouteSvg from '../../../img/svg/route'
import EuroSvg from '../../../img/svg/euro'

import HelmetComponent from '../../../components/Helmet'
import LiefletComponent from './Lieflet'
import SliderComponent from './Slider'
import ThreeEventsComponent from './ThreeEvents'
import ThreePoisesComponent from './ThreePoises'

const EventView: React.FC = () => {

  const { id } = useParams()
  const { isLoading, EventData } = useEvent(id || '')
  const navigate = useNavigate();

  const { t } = useTranslation()

  const [event, setEvent] = useState<any>({})
  const [showMap, setShowMap] = useState<boolean>(false)

  useEffect(() => {
    if(EventData)
      setEvent(EventData)
  }, [EventData])


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  if (isLoading || Object.keys(event).length < 0) {
    return (
      <Row justify="center" >
        <Col span={12} offset={6}>
          <div className={classes.loadingSpinner}> <Spin size="large" /> {t('Παρακαλώ περιμένετε')} ... </div>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <HelmetComponent title='Εξερεύνησε τις ομορφιές των Χανίων' descrition='Εξερεύνησε τις ομορφιές των Χανίων' />
     
      <div className={classes.container}>
        <Row justify="center">
          <Col span={20}>
            <div className={classes.title}>
              {event.title}
            </div>
            <div className={classes.subTitle}>
              {event.category}
            </div>
            <div className={classes.subTitle}>
              { event?.price && event?.price?.length == 0 ? <span className={classes.type}>{t('Είσοδος Ελεύθερη')}</span> : ''}
            </div>
            
          </Col>
        </Row>

        <Row justify="center">
          <Col span={22}>
            <Button size='large' className={classes.BackBtn} onClick={() => navigate(-1)}>{t('Επιστροφή')}</Button>
          </Col>
        </Row>

      </div>

      {showMap && event.venues[0].length !== 0 &&
        <>  
        <div className={classes.container}>
          <Row justify="center">
            <Col xs={24} sm={24} md={{ span: 16 }} lg={{ span: 16 }}>
              <div className={[classes.btn, classes.btnPadding].join(" ")}>
                <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={{ span: 16 }} lg={{ span: 16 }}>
              <LiefletComponent
                venues={event.venues}
                image={event.image}
                title={event.title}
                latitude={event.venues[0].coordinate_x}
                longitude={event.venues[0].coordinate_y}
              />
            </Col>
          </Row>
        </div>
        </>
      } 
      
      <div className={classes.checkBoxesContainerPoint}>
        <Row justify="center">
          <Col xs={24} sm={24} md={{ span: 16 }} lg={{ span: 16 }} >
            {!showMap && event?.venues && event?.venues.length !== 0 &&
              <div className={classes.btn}><Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button></div>
            }
          </Col>
        </Row>
      </div>

      
      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <div className={classes.description}>
           
            <div className={classes.eventTitle}>{t('Περιγραφή')}</div>

            <div className={classes.eventDescription} dangerouslySetInnerHTML={{ __html: event.description }} />

            {event.gallery && event.gallery.length !== 0 &&
              <>
                <div className={classes.eventPhoto}>{t('Πολυμεσικό Υλικό Εκδήλωσης')}</div>
                <SliderComponent images={event.gallery} />
              </>
            }
            
            <div className={classes.eventInformation}>{t('Πληροφορίες')}</div>

            <div className={classes.MyRowColumnDescription}>

              <p>{<GlobusSvg width={24} height={24} fill="#275674" />} <span>{event.municipality}</span> </p>
      
              {event.time_periodsTwo && event?.time_periodsTwo.length !== 0 &&
                <p><ClockSvg width={24} height={24} fill="#275674" />
                  <span>{event.time_periodsTwo[0].starting_date} - {event.time_periodsTwo && event.time_periodsTwo[0].ending_date}</span>
                </p>
              }

              {event.venues && event?.venues.length !== 0 &&
                <p><MapSvg width={24} height={24} fill="#275674" />
                  <span>{event.venues[0].title}</span>
                </p>
              }

              {event.urls && event?.urls.length !== 0 &&
                <p><RouteSvg width={24} height={24} fill="#275674" />
                  <span className={classes.url} onClick={()=>window.location.href = event.urls[0].url}>{event.urls[0].url}</span>
                </p>
              }

              <p>{<EuroSvg width={24} height={24} fill="#275674" />} <span className={classes.price}>{event?.price && event?.price.length == 0 ? t('Είσοδος Ελεύθερη') : event?.price && event?.price[0]?.price + ' (' + event?.price[0]?.description + ')'}</span></p>

            </div>
         </div>
        </Col>
      </Row> 

      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }} >
          <ThreeEventsComponent />
        </Col>
      </Row>

      <div style={{ backgroundColor: '#f3f8fd' }}>
        <Row justify="center">
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }} >
            <ThreePoisesComponent />
          </Col>
        </Row>
      </div>

    </>
  )
}

export default EventView
