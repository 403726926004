class Utils {

  // static Capitalize = (str) => {
	// 	let string = str.toLowerCase()
	// 	return string.charAt(0).toUpperCase() + string.slice(1);
  // }


	// static getCurrentDate(separator=' '){

	// 	let newDate = new Date()
	// 	let date = (newDate.getDate() < 10) ? `0${newDate.getDate()}` : `${newDate.getDate()}`;
	// 	let month = newDate.toLocaleString("en-US", { month: "short" })

	// 	return {date, month}
	// }


  // static filterArray(list, type, month, municipality, current) {

  //   let data = list

	// 	let newDate = new Date()
	// 	let today = new Date().getTime()
	// 	let date = (newDate.getDate() < 10) ? `0${newDate.getDate()}` : `${newDate.getDate()}`;
	// 	let months = newDate.toLocaleString("en-US", { month: "short" })


	// 	if (type === "allEvents" &&
	// 		(month === "allMonths" || month === "Month") &&
	// 		municipality === "allMunicipality" &&
	// 		current === "Current") {
	// 		return data
	// 	}

	// 	else {
	// 		  let array_current = [];
	// 		  data = list.filter(item => {

	// 				let array_type = (type !== "allEvents") ? (item['type'] === type) : true
	// 				let array_month = (month !== "allMonths") ? (item['month_code'] === month) : true
	// 				let array_municipality = (municipality !== "allMunicipality") ? (item.municipality===municipality) : true

	// 				switch (current) {
	// 					case 'cur':
	// 						array_current = ( item['day'] === date && item['month_code'] === months ); break;
	// 					case 'att':
	// 						array_current = today < item['day_code']; break;
	// 					case 'old':
	// 						array_current = today > item['day_code']; break;
	// 					default:
							
	// 				}

	// 				return (array_type && array_month && array_municipality && array_current)
	// 			})
	// 	}
	// 	return data
  // }
  


  static filterPoisesArray(list, environmentSelected, historySelected, municipalitiesSelected, selectSort) {
	
		let ListArray = []

		if(selectSort == 'rate')
		list = [...list].sort((a, b) => a.rate > b.rate ? 1 : -1);


		if (environmentSelected.length !== 0) {
			for (let i in environmentSelected[0]){
				list.map(item => (item.category_id === environmentSelected[0][i]) && ListArray.push(item))
			}
		}

		if (historySelected.length !== 0) {
			for (let i in historySelected[0]){
				list.map(item => (item.category_id === historySelected[0][i]) && ListArray.push(item))
			}
		}

		if ( municipalitiesSelected.length !== 0) {

			if (environmentSelected.length == 0 && historySelected.length == 0)
				ListArray = list

			if(municipalitiesSelected[0].length !== 0)
				ListArray = ListArray.filter(item => { 

					let q = item.municipality === municipalitiesSelected[0][0]
					let w = item.municipality === municipalitiesSelected[0][1]
					let e = item.municipality === municipalitiesSelected[0][2]
					let r = item.municipality === municipalitiesSelected[0][3]
					let t = item.municipality === municipalitiesSelected[0][4]
					let y = item.municipality === municipalitiesSelected[0][5]
					let u = item.municipality === municipalitiesSelected[0][6]
					
					return ( q || w || e || r || t || y || u )
				})
				
			else {
			  if(selectSort == 'rate')
			  ListArray = [...ListArray].sort((a, b) => a.rate < b.rate ? 1 : -1);
				return ListArray	
			}	
				
		}

		else {
			if(selectSort == 'rate')
			ListArray = [...ListArray].sort((a, b) => a.rate < b.rate ? 1 : -1);
			return ListArray
		}

		// if(selectSort == 'rate')
		ListArray = [...ListArray].sort((a, b) => a.rate < b.rate ? 1 : -1);
		
		



		return ListArray
  }

  static filterRoutesArray(list, routesSelected, municipalitiesSelected) {
	
		let ListArray = []


		if (routesSelected.length == 0 && municipalitiesSelected == 0) {
			ListArray = list
		}

		if (routesSelected.length !== 0) {
			for (let i in routesSelected[0]){
				list.map(item => (item.extras._route_type.value[0] == routesSelected[0][i]) && ListArray.push(item))
			}
		}

		if ( municipalitiesSelected.length !== 0) {

			if (routesSelected.length == 0)
				ListArray = list

			if (municipalitiesSelected[0].length !== 0)
				ListArray = ListArray.filter(item => { 

					let q = item.place === municipalitiesSelected[0][0]
					let w = item.place === municipalitiesSelected[0][1]
					let e = item.place === municipalitiesSelected[0][2]
					let r = item.place === municipalitiesSelected[0][3]
					let t = item.place === municipalitiesSelected[0][4]
					let y = item.place === municipalitiesSelected[0][5]
					let u = item.place === municipalitiesSelected[0][6]
					
					return ( q || w || e || r || t || y || u )
				})
				
			else {
				return ListArray	
			}	
				
		}

		else {
			return ListArray
		}

		return ListArray
  }

  static filterEventsArray(list, eventsSelected, datesSelected, municipalitiesSelected, dateRangeStartProp, dateRangeEndProp) {

		let ListArray = []
		let eventsArray = []

		if (eventsSelected.length !== 0) {
			for (let i in eventsSelected[0]){
				list.map(item => (item.category_type === eventsSelected[0][i]) && ListArray.push(item))
			}
			// console.log('Events', ListArray)
		}

		if (datesSelected.length !== 0) {

			ListArray.map(item => {

				Date.prototype.addDays = function (days) {
					const date = new Date(this.valueOf());
					date.setDate(date.getDate() + days);
					return date;
				}
				
				let date = new Date();

				if (datesSelected[0] === 'today') {
					// console.log("------- TODAY -------")

					const dateSelected = returnDate(date.toLocaleString())

					for (let i = 0; i < item.time_periods.length; i++){
					
						const startDate = returnDate(item.time_periods[i].starting_date)
						const endDate = returnDate(item.time_periods[i].ending_date)

						item.time_periods[i].starting_date === '12/10/1983, 12:00' &&	eventsArray.push(item)
				
						if (dateSelected > startDate && dateSelected < endDate) {
							eventsArray.push(item);
						}
					}
				}

				if (datesSelected[0] === 'week') {
					// console.log("------- THIS WEEK -------")

					let WeekDay = date.getDay() //  1, 2, 3
					const dateFinishWeek = date.addDays(parseInt(7 - WeekDay))
					  
					const todayStart = returnDate(date.toLocaleString())
					const dateFinish = returnDate(dateFinishWeek.toLocaleString())
					
					for (let i = 0; i < item.time_periods.length; i++){
					
						const startDate = returnDate(item.time_periods[i].starting_date)
						const endDate = returnDate(item.time_periods[i].ending_date)
		
						item.time_periods[i].starting_date === '12/10/1983, 12:00' && eventsArray.push(item)

						if ( todayStart <= endDate && !( startDate >= dateFinish)) {
							eventsArray.push(item);
						}
					}
				}

				if (datesSelected[0] === 'month') {
					// console.log("------- THIS MONTH -------")
					let thisMonthDays = daysInMonth(date.getMonth(), date.getFullYear())
					let days = thisMonthDays - date.getDate()
					let lastDayOfMonth = date.addDays(parseInt(days)) 

					const today = date
					const todayStart = returnDate(today.toLocaleString())

					for (let i = 0; i < item.time_periods.length; i++){
					
						const startDate = returnDate(item.time_periods[i].starting_date)
						const endDate = returnDate(item.time_periods[i].ending_date)
		
						item.time_periods[i].starting_date === '12/10/1983, 12:00' && eventsArray.push(item)
				
						if ( todayStart <= endDate && !( startDate >= lastDayOfMonth)) {
							eventsArray.push(item)
						}
					}
				}

				if (datesSelected[0] === 'nextMonth') {
					// console.log("------- NEXT MONTH -------")
				
					let nextMonth = ''
					date.getMonth() == 11 ? nextMonth = new Date(date.getFullYear() + 1, 0, 1) : nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1)

					for (let i = 0; i < item.time_periods.length; i++){
					
						const startDate = returnDate(item.time_periods[i].starting_date)
						const endDate = returnDate(item.time_periods[i].ending_date)

						item.time_periods[i].starting_date === '12/10/1983, 12:00' && eventsArray.push(item)
				
						if ( nextMonth <= endDate && nextMonth >= startDate ) {
							eventsArray.push(item)
						}
					}
				}

				if (datesSelected[0] === 'range'){
					// console.log("------- RANGE -------")

					for (let i = 0; i < item.time_periods.length; i++){
					
						const startDate = returnDate(item.time_periods[i].starting_date)
						const endDate = returnDate(item.time_periods[i].ending_date)
		
						item.time_periods[i].starting_date === '12/10/1983, 12:00' && eventsArray.push(item)
				
						if (((startDate <= dateRangeStartProp && dateRangeStartProp <= endDate) || (startDate <= dateRangeEndProp && dateRangeEndProp <= endDate)) ||
							(dateRangeStartProp <= startDate && endDate <= dateRangeEndProp)) {
							eventsArray.push(item)
							continue;
						}
					}
				}

			})
			// console.log('Dates', eventsArray)
			return eventsArray
			// return eventsArray
		}




		if ( municipalitiesSelected.length !== 0) {

			if (eventsSelected.length == 0 && datesSelected.length == 0)
				ListArray = list

			if (municipalitiesSelected[0].length !== 0)
				ListArray = ListArray.filter(item => { 

					let q = item.place === municipalitiesSelected[0][0]
					let w = item.place === municipalitiesSelected[0][1]
					let e = item.place === municipalitiesSelected[0][2]
					let r = item.place === municipalitiesSelected[0][3]
					let t = item.place === municipalitiesSelected[0][4]
					let y = item.place === municipalitiesSelected[0][5]
					let u = item.place === municipalitiesSelected[0][6]
					
					return ( q || w || e || r || t || y || u )
				})
				
			else {
			 
				return ListArray	
			}	
				
		}

		else {
			return ListArray
		}

		console.log('ListArray', ListArray)
		return ListArray
  }


  static filterFavoritePoisesArray(list, municipalitiesSelected) {
	
		let ListArray = []

		if ( municipalitiesSelected.length == 0) {
			ListArray = list
		}

		if (municipalitiesSelected.length !== 0) {
			for (let i in municipalitiesSelected) {
				list.map(item => (item.municipality === municipalitiesSelected[i]) && ListArray.push(item))
			}
		}

		return ListArray
  }
	














	
  static filterRoutesIdPopiline(list, idPopiline) {
	
		let data = list.filter(item => {

			return item['id'] === idPopiline
		})

		return data
	}



  static filterPoints(list, North, East, South, West ) {
	
		let data = list.filter(item => {

			let array_North = (item.lat < North) 
			let array_East = (item.lng < East) 
			let array_South = (item.lat > South) 
			let array_West = (item.lng > West) 

			return ( array_North && array_East && array_South && array_West )
		})
		return data
	}



	static FilterRoutes(Routes, regionsSelected, routesSelected ) {
	
		let regionsArray = []
		let routesArray = []

		// For Route Page
		if (regionsSelected.length === 0 && routesSelected.length === 0) {
			return Routes
		}
		else if (regionsSelected.length === 0 && routesSelected.length != 0) {
			for (const route in routesSelected){
				Routes.map(item => (item.route_type === routesSelected[route]) && routesArray.push(item))
			}
			return routesArray
		}

		else {

			for (const region in regionsSelected){
				Routes.map(item => (item.municipality === regionsSelected[region]) &&	regionsArray.push(item))
			}

			for (const route in routesSelected){
				regionsArray.map(item => (item.route_type === routesSelected[route]) && routesArray.push(item))
			}

			return routesArray
		}

	}

	
  static MapFilterRoutes(Routes, regionsSelected, routesSelected, check ) {
	
		let regionsArray = []
		let routesArray = []

		if (check)
			return Routes
		
		else {
						for (const region in regionsSelected){
						Routes.map(item => (item.municipality === regionsSelected[region]) &&	regionsArray.push(item))
					}

						for (const route in routesSelected){
						regionsArray.map(item => (item.route_type === routesSelected[route]) && routesArray.push(item))
					}

			return routesArray
		}
		
	}



  static MapFilterEvents(Events, regionsSelected, optionsSelected, daySelected ) {
	
		let regionsArray = []
		let optionsArray = []
		let eventsArray = []

		for (const region in regionsSelected) {
			Events.map(item => (item.municipality === regionsSelected[region]) &&	regionsArray.push(item))
		}

		for (const option in optionsSelected) {
			regionsArray.map(item => (item.category_type === optionsSelected[option]) && optionsArray.push(item))
		}


		optionsArray.map(item => {

			Date.prototype.addDays = function (days) {
				const date = new Date(this.valueOf());
				date.setDate(date.getDate() + days);
				return date;
			}
			
			let date = new Date();
			date = date.addDays(parseInt(daySelected))
      const dateSelected = returnDate(date.toLocaleString())


			if (daySelected == '40'){
				eventsArray = optionsArray
			} else {

				for (let i = 0; i < item.time_periods.length; i++){
				
					const startDate = returnDate(item.time_periods[i].starting_date)
					const endDate = returnDate(item.time_periods[i].ending_date)
	
					if (item.time_periods[i].starting_date == '12/10/1983, 12:00'){
						eventsArray.push(item)
						continue;
					}
			
					if (dateSelected > startDate && dateSelected < endDate) {
						eventsArray.push(item);
						continue;
					}
				}
			}

		})



			// const dateSelected = returnDate(date.toLocaleString())
			// const startDate = returnDate(item.time_periods[0].starting_date)
			// const endDate = returnDate(item.time_periods[0].ending_date)

			// if (item.time_periods[0].starting_date == '12/10/1983, 12:00' || daySelected == '40')
			// 	  eventsArray.push(item)
			// else {
			// 	if (dateSelected > startDate && dateSelected < endDate)
			// 		eventsArray.push(item)	
			// }

		
			// return null
		// })

		return eventsArray
	}

}


function returnDate(Str) {

	const [StrDay, StrHour ] = Str.split(',')
	const [day, month, year] = StrDay.split('/')
	const date = new Date(+year, month - 1, +day)

	return date
}
	
function daysInMonth (month, year) {
	return new Date(year, month, 0).getDate();
}


export default Utils