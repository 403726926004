import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface EventsArray{
  value: number;
}
interface DatesArray{
  value: number;
}
interface MunicipalityArray{
  value: number;
}

interface EventState {
  EventsRedux: EventsArray[];
  DatesRedux: DatesArray[];
  MunicipalityRedux: MunicipalityArray[];
}

const initialState: EventState = {
  EventsRedux:  [],
  DatesRedux: [],
  MunicipalityRedux: []
};

export const EventSlice = createSlice({
  name: 'Events',
  initialState,
  reducers: {
    EventsRedux: (state, action) => {
      state.EventsRedux.splice(0, state.EventsRedux.length);
      state.EventsRedux.push(action.payload)
    },
    DatesRedux: (state, action) => {
      state.DatesRedux.splice(0, state.DatesRedux.length);
      state.DatesRedux.push(action.payload)
    },
    MunicipalityRedux: (state, action) => {
      state.MunicipalityRedux.splice(0, state.MunicipalityRedux.length);
      if(action.payload !== '')
        state.MunicipalityRedux.push(action.payload)
      
    }
  },
})

export const { EventsRedux, DatesRedux, MunicipalityRedux } = EventSlice.actions
export default EventSlice.reducer 