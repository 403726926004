import React, { useState, useEffect, useRef }  from 'react'
import { Row, Col, Button, Form, Input, Checkbox, message } from 'antd'
import classes from "./styles.module.css"
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import TitleAndSubtitleAndBtn from '../../components/PageComponents/TitleAndSubtitleAndBtn'
import ReturnAndHomeBtn from '../../components/PageComponents/ReturnAndHomeBtn'

import { UserOutlined, MailOutlined, NotificationOutlined } from '@ant-design/icons'

import ReCAPTCHA from "react-google-recaptcha"
import emailjs from "@emailjs/browser"
import ky from 'ky'

const { TextArea } = Input;

// const postQuestBook = async (data:any) => {
//   const response = await ky.post('https://lindos-backend.dotsoft.gr/webform_rest/submit', data)
//   return response
// };


const CommentForm: React.FC = () => {

  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const captchaRef = useRef<any>(null)
  const [disablesSendButton, setDisablesSendButton] = useState<boolean>(true)

  const onReset = () => {
    form.resetFields();
  }

  const onFill = () => {
      form.setFieldsValue({ username:'sergios', email: 'ser11@mail.ru', portal: '/page/sergios/admin', comments: 'Sergios is The Best programmer' });
  }

  const onFinish = (values: any) => {

    const QuestBookData = { ...values }
    if (values.remember == true) {

      emailjs.send('service_q7sejnp', 'template_kna1hop', QuestBookData, 'g34S81ch-arEImhHu').then(function(response) {
        console.log('SUCCESS!', response.status, response.text)
        message.success({
          content: t("Ευχαριστούμε που επικοινωνήσατε μαζί μας."),
          className: 'messageSuccess',
          duration: 2,
          style: { marginTop: '20vh'}
        })
        }, function (error) {
            console.log('FAILED...', error);
      })

      setDisablesSendButton(true)
      onReset()
      captchaRef.current.reset()

    }
    else {
      messageApi.open({
        type: 'error',
        content: t('Παρακαλώ αποδεχτείτε τους Όρους χρήσης και την Πολιτική Απορρήτου'),
      })
    }
  }

  function onChange() {
    setDisablesSendButton(false)
  }


  return (
    <>
      {contextHolder}
      <HelmetComponent title='Φόρμα Σχολιασμού Περιεχομένου' descrition='Φόρμα Σχολιασμού Περιεχομένου' />
     
      <TitleAndSubtitleAndBtn title={t('Φόρμα Σχολιασμού Περιεχομένου')} subTitle={''}/>
      
      <div>
        <div className={classes.subTitle}>
          {t('Εναλλακτικά μπορείτε να επικοινωνήσετε στο')} <p className={classes.email}><br />hi@explore-chania.gr </p>
        </div>
      </div>

      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 8 }} lg={{ span: 8 }}>
          <div className={classes.centerForm}>

            <Form
              layout="vertical"
              autoComplete="off"
              // wrapperCol={{ span: 22 }}
              form={form}
              onFinish={onFinish}
              // style={{ maxWidth: 500 }}
            >
              <Form.Item label={t('Ονοματεπώνυμο')} name="username" className={classes.label} hasFeedback rules={[{ required: true, message: 'Πληκτρολόγησε Όνομα και Επώνυμο' }]}>
                <Input size="large" prefix={<UserOutlined className={classes.colorGrey} />} placeholder={t('Όνομα και Επώνυμο εδώ') || undefined} />
              </Form.Item>

              <Form.Item label="Email" name="email" className={classes.label} hasFeedback rules={[{ type: 'email', required: true, message: 'Πληκτρολόγησε το Email σου' }]}>
                <Input size="large" prefix={<MailOutlined className={classes.colorGrey} />} placeholder={'you@you.com' || undefined} />
              </Form.Item>

              <Form.Item label={t('Σελίδα της Πύλης που θέλετε να σχολιάσετε')} className={classes.label} name="portal" hasFeedback rules={[{ required: true, message: 'Πληκτρολογήστε την Σελίδα της Πύλης που θέλετε να σχολιάσετε' }]}>
                <Input size="large" prefix={<NotificationOutlined className={classes.colorGrey} />} placeholder={'you.com' || undefined} />
              </Form.Item>

              <Form.Item label={t('Σχόλια περιεχομένου')} name="comments" className={classes.label} hasFeedback rules={[{ required: true, message: 'Πληκτρολογήστε Σχόλια' }]}>
                <TextArea rows={6} placeholder={t('Πληκτρολογήστε το κείμενο που επιθυμείτε') || undefined} maxLength={12} />
              </Form.Item>


              <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 22 }}>
                <div className={classes.checkBox}>
                  <Checkbox></Checkbox>&nbsp;&nbsp;
                  {t('Συμφωνώ με την')} <a href='/' className={classes.underline} >{t('Πολιτική Απορρήτου')}</a>
                </div>
              </Form.Item>

              <ReCAPTCHA
                sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                ref={captchaRef}
                onChange={onChange}
                g-recaptcha-response="true"
                size='normal'
                style={{marginBottom: 40}}
              />

              <Form.Item>
                <Button
                  htmlType="submit"
                  className={classes.sendCommentBtn}
                  disabled={disablesSendButton}
                  // className={disablesSendButton ? `${classes.subm}` : `${classes.subm2}`}
                
                >{t('Αποστολή Σχολίων')}</Button>
                <br/>
                <Button type="link" htmlType="button" onClick={onFill} >Συμπληρώστε τη φόρμα Sergios...</Button>
              </Form.Item>

              
            </Form>
            
          </div>
        </Col>
      </Row> 

      <ReturnAndHomeBtn />

    </>
  )
}

export default CommentForm