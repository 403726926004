
import React from "react"
import Props from './svgProps'

const CompassSvg = ({width, height, fill}:Props) => {

    return (
    <svg
        width={width}
        height={height}
        viewBox="0 0 22 23"
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={fill}
                fillRule='evenodd'
                d="M16.19,5.81a1,1,0,0,0-1-.21L8.71,8.19a.93.93,0,0,0-.51.52L5.61,15.19a.93.93,0,0,0,.21,1,.91.91,0,0,0,1,.19l6.49-2.59a.91.91,0,0,0,.51-.51l2.59-6.49A.9.9,0,0,0,16.19,5.81Zm-4,6.42L8.11,13.89,9.76,9.76,13.89,8.1Zm4-6.42a1,1,0,0,0-1-.21L8.71,8.19a.93.93,0,0,0-.51.52L5.61,15.19a.93.93,0,0,0,.21,1,.91.91,0,0,0,1,.19l6.49-2.59a.91.91,0,0,0,.51-.51l2.59-6.49A.9.9,0,0,0,16.19,5.81Zm-4,6.42L8.11,13.89,9.76,9.76,13.89,8.1ZM22,10a5.6,5.6,0,0,0-.09-.73,2,2,0,0,0-.07-.41,4.29,4.29,0,0,0-.14-.62L21.58,8c-.07-.24-.15-.49-.23-.72a5,5,0,0,0-.24-.58,2.92,2.92,0,0,0-.19-.44c-.11-.23-.23-.45-.35-.66a5.7,5.7,0,0,0-.45-.71A.73.73,0,0,0,20,4.73c-.15-.23-.33-.46-.5-.68A11.4,11.4,0,0,0,18.2,2.69c-.2-.18-.41-.34-.62-.5a10.21,10.21,0,0,0-1.34-.86,4.37,4.37,0,0,0-.43-.22c-.18-.09-.35-.17-.53-.24L14.94.73c-.23-.08-.46-.17-.7-.24s-.59-.17-.91-.24a11,11,0,0,0-4.66,0c-.31.07-.62.15-.91.24s-.47.16-.7.24L6.72.87c-.16.07-.33.14-.48.22a4.3,4.3,0,0,0-.48.24,11.73,11.73,0,0,0-1.34.86,5.37,5.37,0,0,0-.58.49A8.81,8.81,0,0,0,2.48,4.05c-.17.22-.35.45-.5.68a.3.3,0,0,0-.09.13,7.09,7.09,0,0,0-.44.71A6.68,6.68,0,0,0,1,6.36a.07.07,0,0,0,0,.06A9.33,9.33,0,0,0,.5,7.73s0,.1,0,.15-.07.24-.1.37,0,0,0,.06a6.74,6.74,0,0,0-.17.75c-.05.31-.1.64-.12.95a8.15,8.15,0,0,0,0,1,11,11,0,0,0,20.67,5.25,9.84,9.84,0,0,0,.67-1.47c.11-.32.22-.67.31-1A10.87,10.87,0,0,0,22,11,8.15,8.15,0,0,0,22,10ZM11,20.16A9.17,9.17,0,1,1,20.17,11,9.17,9.17,0,0,1,11,20.16ZM15.2,5.6,8.71,8.19a.93.93,0,0,0-.51.52L5.61,15.19a.93.93,0,0,0,.21,1,.91.91,0,0,0,1,.19l6.49-2.59a.91.91,0,0,0,.51-.51l2.59-6.49a.9.9,0,0,0-.2-1A1,1,0,0,0,15.2,5.6Zm-3,6.63L8.11,13.89,9.76,9.76,13.89,8.1Z" />
    </svg>
    )
}

export default CompassSvg