
import React from "react"
import Props from './svgProps'

const ClockSvg = ({width, height, fill}:Props) => {

    return (
    <svg
        width={width}
        height={height}
        viewBox="0 0 22 23"
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8599 5V11L15.8132 13M21.7432 11C21.7432 16.5228 17.3183 21 11.8599 21C6.40148 21 1.97656 16.5228 1.97656 11C1.97656 5.47715 6.40148 1 11.8599 1C17.3183 1 21.7432 5.47715 21.7432 11Z"
                stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

    </svg>
    )
}

export default ClockSvg