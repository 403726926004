
import React from "react"
import Props from './svgProps'

const EuroSvg = ({width, height, fill}:Props) => {

    return (
    <svg
        width={width}
        height={height}
        viewBox="0 0 22 23"
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5938 3.51903C16.1113 2.25973 14.1913 1.5 12.0938 1.5C7.39933 1.5 3.59375 5.30558 3.59375 10C3.59375 14.6944 7.39933 18.5 12.0938 18.5C14.1913 18.5 16.1113 17.7403 17.5938 16.481M1.59375 12H11.5938M1.59375 8H11.5938"
                stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    )
}

export default EuroSvg