import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface HistoryArray{
  value: number;
}
interface EnvironmentArray{
  value: number;
}
interface MunicipalityArray{
  value: number;
}

interface CategoryState {
  History: HistoryArray[];
  Environment: EnvironmentArray[];
  Municipality: MunicipalityArray[];
}

const initialState: CategoryState = {
  History:  [],
  Environment: [],
  Municipality: []
};

export const CategorySlice = createSlice({
  name: 'Categories',
  initialState,
  reducers: {
    History: (state, action) => {
      state.History.splice(0, state.History.length);
      state.History.push(action.payload)
    },
    Environment: (state, action) => {
      state.Environment.splice(0, state.Environment.length);
      state.Environment.push(action.payload)
    },
    Municipality: (state, action) => {
      state.Municipality.splice(0, state.Municipality.length);
      if(action.payload !== '')
        state.Municipality.push(action.payload)
      
    }
  },
})

export const { History, Environment, Municipality } = CategorySlice.actions
export default CategorySlice.reducer 