import React, { useState } from "react"
import { Col, Row, Radio, DatePicker } from 'antd'
import type { Dayjs } from 'dayjs'
import type { RadioChangeEvent } from 'antd'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"
import { DatesData } from './dates'
import 'dayjs/locale/el';
import locale from 'antd/es/date-picker/locale/el_GR'

import CalendarSvg from '../../img/svg/calendar'


// Redux
import { useDispatch } from 'react-redux'
import { DatesRedux } from '../../redux/mapPage/event'


interface Props{
  checkedId: any[],
  setDateRangeStart: any
  setDateRangeEnd:any
}

const { RangePicker } = DatePicker;

const CheckBoxDatesComponent: React.FC<Props> = ({ checkedId, setDateRangeStart, setDateRangeEnd}) => {

  const { t } = useTranslation()

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']

  const dispatch = useDispatch()

  const onChangeDate = (e: RadioChangeEvent) => {
    dispatch(DatesRedux(e.target.value))
  }

  const onChangeRangePicker = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
   
    if (dates) {

      dispatch(DatesRedux('range'))
      setDateRangeStart(dates[0]?.toDate())
      setDateRangeEnd(dates[1]?.toDate())
      // console.log('From: ', dates[0]?.toDate(), ', to: ', dates[1]?.toDate());
      // console.log('From: ', dateStrings, ', to: ', dateStrings[1]);
    } else {
      console.log('Clear');
    }
  }


  return (
    <>
      <div className={classes.divider}><CalendarSvg width={25} height={30} fill="#275674" />&nbsp;&nbsp;{t('Ημερομηνία')}</div>
      <Row>
        <Radio.Group onChange={onChangeDate} defaultValue={checkedId && checkedId[0]}>
          {DatesData.map((item: any) => {
            return (
              <Col span={24} key={item.id} className={classes.checkboxGroup}>
                <Radio value={item.id} onChange={onChangeDate}>{t(item.item)}</Radio >
              </Col>
            )
          })}
          <Col span={24} className={classes.checkboxGroup}>

            <div className={classes.checkboxGroupRange}>* {t('Έυρος ημερομηνιών')}</div>
            <RangePicker
              bordered={false}
              placeholder={[t('Από'), t('Έως')]}
              format={dateFormatList}
              onChange={onChangeRangePicker}
              locale={locale} />

          </Col>
        </Radio.Group>
      </Row>
    </>
  )
}

export default CheckBoxDatesComponent