import ky from 'ky'
import {API, HeaderForUser} from './configuration'

export const user = {

  async loginUser(email: string, password: string) {
    const data: any = await ky.post(`${API}/en/login?email=${email}&password=${password}`, { headers: HeaderForUser }).json()
    return data
  },
  
  async registerUser(username: string, email: string, password: string) {
    const data: any = await ky.post(`${API}/en/register?name=${username}&email=${email}&password=${password}`, { headers: HeaderForUser }).json()
    return data.data
  },




  async userFavoriteAddOrRemovePoint(id: string, idPoint: string, removeOrAdd: number ) {
    const data: any = await ky.post(`${API}/editFavorites?user_id=${id}&poi_id=${idPoint}&add=${removeOrAdd}`, { headers: HeaderForUser }).json()
    return data.success
  },


  async userFavoritePoints(idUser: string) {
    const data: any = await ky.get(`${API}/favorites?user_id=${idUser}`, { headers: HeaderForUser }).json()
    return data.success
  },

}