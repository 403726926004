import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import classes from "./Categories.module.css"


export const SampleNextArrow = (props: any) => {
  const { onClick } = props
  return (
    <div
      className={classes.Next}
      onClick={onClick}
    >
      <span style={{marginLeft: '10px'}}><RightOutlined /></span> 
    </div>
  )
}


export const SamplePrevArrow = (props: any) => {
  const { onClick } = props
  return (
    <div
      className={classes.Prev}
      onClick={onClick}
    >
      <span style={{marginLeft: '6px'}}><LeftOutlined /></span>  
    </div>
  )
}


export const SampleNextArrowEvents = (props: any) => {
  const { onClick } = props
  return (
    <div
      className={classes.NextEvents}
      onClick={onClick}
    >
      <span style={{marginLeft: '9px'}}><RightOutlined /></span> 
    </div>
  )
}


export const SamplePrevArrowEvents = (props: any) => {
  const { onClick } = props
  return (
    <div
      className={classes.PrevEvents}
      onClick={onClick}
    >
      <span style={{marginLeft: '-42px', position: 'absolute'}}><LeftOutlined /></span>  
    </div>
  )
}







export const settingsCarouselCategories = {
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 5,
          infinite: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
}


export const settingsCarouselEvents = {
  dots: false,
  arrows:true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrowEvents />,
  prevArrow: <SamplePrevArrowEvents />,
  responsive: [
    {
      breakpoint: 1056,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 5,
        infinite: true,
      }
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}