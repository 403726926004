
import React from "react"
import Props from './svgProps'
    
const ExhibitionSvg = ({width, height, fill}:Props) => {
    return (
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.96 20">     
        <path fill={fill} d="M18,14.12H9.87l-.15-.35a4.11,4.11,0,0,0-1.25-1.6L8,11.79l3.09-3.33a1.26,1.26,0,0,1,1.7,0L14,9.76l.42-.44A1.17,1.17,0,0,1,15.29,9h0a1.16,1.16,0,0,1,.84.34l2.7,2.81a1.22,1.22,0,0,1,.23,1.26h0A1.18,1.18,0,0,1,18,14.12ZM10.61,13H18l.55.19L18,13a.06.06,0,0,0,0-.07l-2.67-2.78L14,11.42,12,9.24,9.64,11.68A5.07,5.07,0,0,1,10.61,13Z"/>
        <path fill={fill} d="M15.22,8.47a1.9,1.9,0,1,1,1.9-1.9A1.91,1.91,0,0,1,15.22,8.47Zm0-2.66a.76.76,0,1,0,0,1.52.76.76,0,0,0,0-1.52Z"/>
        <path fill={fill} d="M21.23,16.83a.6.6,0,0,0,.58-.59V2.48a.58.58,0,0,0-.58-.59H4.35a.58.58,0,0,0-.59.59V5.25a4.7,4.7,0,0,1,1.17-.11v-2H20.62V15.66H10.41l.15,1.17Z"/>
        <path fill={fill} d="M4.78,18.82a18.24,18.24,0,0,1-3.68-.35A1.39,1.39,0,0,1,0,17l.27-2.2a3.55,3.55,0,0,1,1.61-2.53L2.24,12l.33.28a3.57,3.57,0,0,0,4.43,0L7.31,12l.35.22a3.47,3.47,0,0,1,1.62,2.54l.27,2.19a1.38,1.38,0,0,1-1.08,1.53A18.41,18.41,0,0,1,4.78,18.82ZM2.2,13.41a2.33,2.33,0,0,0-.79,1.48l-.27,2.19a.25.25,0,0,0,.19.27,18.25,18.25,0,0,0,6.91,0,.23.23,0,0,0,.17-.28l-.27-2.18a2.33,2.33,0,0,0-.79-1.49A4.72,4.72,0,0,1,2.2,13.41Z"/>
        <path fill={fill} d="M4.78,12.4A3.33,3.33,0,1,1,8.11,9.07,3.34,3.34,0,0,1,4.78,12.4Zm0-5.52A2.19,2.19,0,1,0,7,9.07,2.19,2.19,0,0,0,4.78,6.88Z"/>
      </svg>
    )
}

export default ExhibitionSvg 