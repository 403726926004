import React from "react"
import { Checkbox, Col, Row } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"

// Redux
import { useDispatch } from 'react-redux'
import { Routes, Municipality }  from '../../redux/mapPage/route'


interface Props{
  icon?: any;
  header?: string;
  array: any;
  type: string;
  setDateRangeStart?: any
  setDateRangeEnd?:any
}

const CheckBoxComponent: React.FC<Props> = ({ icon, header, array, type, setDateRangeStart, setDateRangeEnd }:Props ) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
 
  const onChange = (list: CheckboxValueType[]) => {
    type === 'routes' ? dispatch(Routes(list)) : dispatch(Municipality(list))
  }
  
  if (type === 'municipality') {
    return (
      <>
        {header && <div className={classes.divider}>{icon}&nbsp;&nbsp;{header}</div>}
        <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
          <Row>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value={t('Δήμος Αποκορώνου')}>{t('Αποκορώνου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value={t('Δήμος Γαύδου')}>{t('Γαύδου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value={t('Δήμος Καντάνου - Σελίνου')}>{t('Καντάνου - Σελίνου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value={t('Δήμος Κισσάμου')}>{t('Κισσάμου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value={t('Δήμος Πλατανιά')}>{t('Πλατανιά')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value={t('Δήμος Σφακίων')}>{t('Σφακίων')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
              <Checkbox value={t('Δήμος Χανίων')}>{t('Χανίων')}</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
    </>
  )
  }

  
  return (
    <>
      {header && <div className={classes.divider}>{icon}&nbsp;&nbsp;{t(header)}</div>}
      
      <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
        <Row>
          {array.map((item: any) => {

            return (
              <Col span={24} key={item.id} className={classes.checkboxGroup}>
                <Checkbox value={item.id}>{item.name}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
    </>
  )
}

export default CheckBoxComponent