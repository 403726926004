import React from 'react'
import classes from "./Destination.module.css"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { TagOutlined, EnvironmentOutlined, PushpinOutlined, ArrowRightOutlined } from '@ant-design/icons'

import GlobusSvg from '../../img/svg/globus'
import MapSvg from '../../img/svg/map'

interface Props{
  id: string
  image: string
  title: string
  text: string
  place: string
  type: string
}


const DestinationComponent: React.FC<Props> = ({...props}) => {

  const { t } = useTranslation()

  return (
    <div className={classes.box}>
      <div className={classes.containerImg}>
        <img src={props.image} alt={props.title} className={classes.img} />
        <div className={classes.addToRoute}><TagOutlined />&nbsp;&nbsp; {t('Προσθήκη στη διαδρομή')}</div>
      </div>
      <div className={classes.BoxTitle}>{props.title}</div>
      <div className={classes.BoxDesc}>{props.text}</div>
      <div className={classes.MyRow}>
        <div className={classes.MyRowColumn}>
          <p><GlobusSvg width={24} height={24} fill="#275674"/><span className={classes.text}>{props.place}</span></p>
        </div>
        <div className={classes.MyRowColumn2}>
          {/* <p><PushpinOutlined /> {point.type.charAt(0).toUpperCase() + point.type.slice(1).toLowerCase()}</p> */}
          <p><MapSvg width={24} height={24} fill="#275674" /><span className={classes.text}>{props.type}</span></p>
        </div>
      </div>
      <Link to={`../exploration/${props.id}`}>
        <div className={classes.LookMore}>
          {t('Περισσότερα')}&nbsp;&nbsp;<ArrowRightOutlined style={{ fontWeight: 'bold' }} />
        </div>
      </Link>
    </div>      
  )
}

export default DestinationComponent