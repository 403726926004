import React from 'react'
import { Col, Row, Button, Rate, Popconfirm } from 'antd'
import classes from "./Pois.module.css"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../utils/auth"
import { HeartOutlined, PlusCircleOutlined, CloseCircleOutlined, ExclamationOutlined } from '@ant-design/icons'

import GlobusSvg from '../../img/svg/globus'
import MapSvg from '../../img/svg/map'


interface Props{
  id: string
  image: string
  title: string
  rate: number
  text: string
  municipality: string
  category: string
  isAuth?: boolean
  isAddOrDelete?: boolean
  onClickAddOrRemovePois: (text: string, idPois: string, AddOrDelete: number) => void,
  onClickAddRemovePois: (idPois : string) => void,
}

const PoisComponent: React.FC<Props> = ({id, image, title, rate, text, municipality, category, isAuth, isAddOrDelete, onClickAddOrRemovePois, onClickAddRemovePois }:Props) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useAuth()
  // const [messageApi, contextHolder] = message.useMessage()


  return (
  <>
    {/* {contextHolder} */}
    <div className={classes.PointContainer}>
      <Row>

        <Col xs={24} sm={24} md={5} lg={5}>
          <div><img src={image !== '' ? image : '../img/defaultPoisImage.jpg'} className={classes.PointImg} alt={title} onClick={() => navigate(`/exploration/${id}`, { replace: true })} /></div>
        </Col>

        <Col xs={24} sm={24} md={14} lg={14}>

          <div className={classes.PointCenter}>
            <div className={classes.PointCenterLeft} onClick={() => navigate(`/exploration/${id}`, { replace: true })}>
              {title}
            </div>
            <div className={classes.PointCenterRight}>
              <Rate value={rate} />
            </div>
          </div>

          <div className={classes.PointCenter}>
            <p className={classes.PointCenterCenterP}>{text}...</p>
          </div>

          <div className={classes.PointCenterBottom}>
            <div className={classes.PointCenterButtomLeft}>
              <div><GlobusSvg width={25} height={25} fill="#275674" /><span className={classes.PointTextBtm}>{municipality}</span></div>
            </div>
            <div className={classes.PointCenterButtomRight}>
              <div><MapSvg width={25} height={25} fill="#275674" /><span className={classes.PointTextBtm}>{category}</span></div>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={5} lg={5}>
          <div>
            {(auth.userData.id == '' || auth.userData.id == undefined)
              ?
              <Popconfirm
                title={t('Παρακαλώ συνδεθείτε')}
                //  description="Πρέπει να είστε συνδεδεμένοι για να προσθέσετε σημεία ενδιαφέροντος στη λίστα με τα Αγαπημένα σας."
                onConfirm={() => navigate('/login')}
                icon={<ExclamationOutlined style={{ color: '#275674', fontWeight: '600' }} />}
                okText={t('Σύνδεση')}
                showCancel={false}
              >
                <Button
                  size='large'
                  icon={!isAuth ? < HeartOutlined /> : <CloseCircleOutlined />}
                  className={!isAuth ? classes.PointFavoriteBtn : classes.PointRemoveFavoriteBtn}
                >
                  {!isAuth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση από τη λίστα')}
                </Button>
              </Popconfirm>
              :
              <Button
                size='large'
                icon={!isAuth ? < HeartOutlined /> : <CloseCircleOutlined />}
                className={!isAuth ? classes.PointFavoriteBtn : classes.PointRemoveFavoriteBtn}
                onClick={() => auth.userData.id !== '' && [onClickAddRemovePois(id), isAddOrDelete ? onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'), id, 1) : onClickAddOrRemovePois(t('Σημείο Ενδιαφέροντος διαγράφηκε επιτυχώς'), id, 0)]}>
                {!isAuth ? t('Προσθήκη στα Αγαπημένα') : t('Αφαίρεση από τη λίστα')}
              </Button>
            }
            <Button
              size='large'
              icon={< PlusCircleOutlined />}
              className={classes.PointMoreBtn}
              onClick={() => navigate(`/exploration/${id}`, { replace: true })}>
              {t('Περισσότερα')}
            </Button>
          </div>
        </Col>

      </Row>
      </div>
    </>
  )
}

export default PoisComponent
