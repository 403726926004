import React, { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { axiosExplorations } from "../services/exploration"
import { PoisCategoriesProps, subCategoriesProps } from "./props/exploration"
import { PoisProps, PoisProps2 } from "./props/pois"
import { GeneralProps } from "./props/general"


export const useExploration = () => {
  
  const [poisCategories, setPoisCategories] = useState<PoisCategoriesProps[]>([]); 

  let arrayPoisCategories: PoisCategoriesProps[] = []
  let arraySubCategories: subCategoriesProps[]=[]
  
  const { isLoading, error } = useQuery(['categories'], () => axiosExplorations.getCategories(), {
   
    onSuccess: (data) => {

      data.map((category: any, index:number) => {

        for (let i = 0; i < data[index].children.length; i++){

          arraySubCategories.push({
            id: data[index].children[i].id,
            name: data[index].children[i].name,
            pois_count: data[index].children[i].pois_count,
          })
        } 

        arrayPoisCategories.push({
          id: category.id,
          name: category.name,
          subCategoriesCount: category.pois_count,
          subCategories: arraySubCategories,
        })

        arraySubCategories = []
      })
      
      setPoisCategories(arrayPoisCategories)
      
    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, error, poisCategories }
}


export const usePois = (id: string) => {

  let poisArray: PoisProps2;

  const [PoisData, setPoise] = useState<PoisProps2>(); 
  
  const { isLoading, isFetching, error } = useQuery(['pois', id], () => axiosExplorations.getPois(id), {
   
    onSuccess: (data) => {

        poisArray = {
          
          id: data.id,
          title: data.name,
          description: data.portal_main_info,
          lat: data.coordinates[0].latitude,
          lng: data.coordinates[0].longitude,
          municipality: data.extras._municipalityName.value,
          tags: data.tags,
          image: data.main_image,
          images: data.files[1],
          mp3: data.files[4],
          category: data.category_path[0],
          rating: data.rating !== null ? data.rating : 0,
        }

      setPoise(poisArray)
    },
    
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, PoisData }
}

export const usePoises = () => {

  let poisesArray: GeneralProps[] = []

  const [Poises, setPoises] = useState<GeneralProps[]>([]); 
  
  const { isLoading, isFetching, error } = useQuery(['poises'], () => axiosExplorations.getPoises(), {
   
    onSuccess: (data) => {
      
      data.map((array: PoisProps) => {
        poisesArray.push({
          id: array.id,
          image: array.main_image,
          municipality: array.extras._municipalityName.value,
          title: array.name,
          lat: array.coordinates[0].latitude,
          lng: array.coordinates[0].longitude,
          rate: array.rating !== null ? array.rating : 0,
          vote: array.votesNum !== null ? array.votesNum : 0,
          category_id: array.category_path_ids[0][1],
          category: array.category_path[0][1],
          text: array.portal_main_info,
          
        })
      })

      setPoises(poisesArray)

    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { Poises }
}
