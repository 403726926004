
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Drawer, Space } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useAreas } from '../../hooks/useAreas'
import HelmetComponent from '../../components/Helmet'
import Spinner from '../../components/PageComponents/Spinner'
import TitleAndSubtitle from '../../components/PageComponents/TitleAndSubtitle'
import { CloseOutlined } from '@ant-design/icons'
import CheckBoxEventComponent from '../../components/CheckBoxGroup/Event'
import CheckBoxDatesComponent from '../../components/CheckBoxGroup/Date'
import CheckBoxMunicipalityComponent from '../../components/CheckBoxGroup/Municiplaity'
import utils from '../../utils'

import ViewEventsComponent from './viewEvents'
import LiefletComponent from './Lieflet'

import MapSvg from '../../img/svg/map'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'
import { useEventsMap } from '../../hooks/useEvent'


const Events: React.FC = () => {

  const { t } = useTranslation()

  const { isLoading, Events } = useEventsMap()
  const { areas } = useAreas()

  const ReduxEvents: any[] = useSelector((state: RootState) => state.Events.EventsRedux)
  const ReduxDates: any[] = useSelector((state: RootState) => state.Events.DatesRedux)
  const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Events.MunicipalityRedux)

  const [events, setEvents] = useState<any[]>(Events);

  const [setPoisesMarkerExploration, PoisesMarkerExploration ] = useState<any[]>([]);

  const [showMap, setShowMap] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);


  const [dateRangeStartProp, dateRangeStart] = useState<string>('')
  const [dateRangeEndProp, dateRangeEnd] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setEvents(Events)
    setCheck(true)
  }, [Events])


  useEffect(() => {
    let result = utils.filterEventsArray(Events, ReduxEvents, ReduxDates, ReduxMunicipalities, dateRangeStartProp, dateRangeEndProp)
    setEvents(result)
  }, [ReduxEvents, ReduxDates, ReduxMunicipalities, dateRangeStartProp, dateRangeEndProp])
  
  const showDrawer = () => { setOpen(true) }

  const onClose = () => { setOpen(false) }


  if (isLoading) {
    return (<Spinner />)
  }

  return (
    <>
      <HelmetComponent title='Εκδηλώσεις' descrition='Εκδηλώσεις' />
     
      <TitleAndSubtitle title={t('Βρες τις εκδηλώσεις στα Χανιά')} subTitle={t('Δείτε τις εκδηλώσεις από το Νομό')}/>

      {showMap &&
        <div className={classes.MainContainer}>
          <div className={classes.container}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className={[classes.btn, classes.btnPadding].join(" ")}>
                  <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff"/>} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <LiefletComponent setPoisesMarkerExploration={setPoisesMarkerExploration} />
              </Col>
            </Row>
          </div>
        </div>
      } 
      <div className={classes.MainContainer}>
        <div className={classes.checkBoxesContainerPoint}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>

              <div className={classes.btnFiltersAndMap}>
                <div>
                  <Button
                    type="primary"
                    size='large'
                    className={classes.btnFilters}
                    onClick={showDrawer}> {t('Φίλτρα')}
                  </Button>
                </div>
                {
                  !showMap &&
                  <div className={classes.btn}>
                    <Button
                      type="primary"
                      icon={<MapSvg width={20} height={20} fill="#fff" />}
                      size='large' className={classes.btnMap}
                      onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')}
                    </Button>
                  </div>
                } 
              </div>



              {/* {!showMap &&
                <div className={classes.btn}><Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff"/>} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;{!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button></div>
              } */}
            </Col>
          </Row>
        </div>
      </div>



      <Drawer
        bodyStyle={{ padding: '0px' }}
        autoFocus={false}
        mask={false}
        title={t('Φίλτρα')}
        placement={'left'}
        closable={false}
        onClose={onClose}
        open={open}
        // getContainer={false}
        width={350}
        extra={
          <Space>
            <Button
              icon={<CloseOutlined />}
              onClick={onClose}
              type="text"
              style={{ position: 'absolute', top: 8, right: 0 }}
            />
          </Space>
        }
      >
        <div className={classes.checkBoxesContainer}>
          <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
          <CheckBoxEventComponent checkedId={ReduxEvents} /><hr />
          <CheckBoxDatesComponent
            checkedId={ReduxDates}
            setDateRangeStart={dateRangeStart}
            setDateRangeEnd={dateRangeEnd} /> <hr />
          <CheckBoxMunicipalityComponent checkedId={ReduxMunicipalities} />
        </div>
      </Drawer>




      <div className={classes.MainContainer}>
        <Row justify="center">
          <Col xs={24} sm={24} md={5} lg={5}>
          <span className={classes.hideIsMobile}>
            <div className={classes.checkBoxesContainer}>
              <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
              <CheckBoxEventComponent checkedId={ReduxEvents} /><hr />
              <CheckBoxDatesComponent
                checkedId={ReduxDates}
                setDateRangeStart={dateRangeStart}
                setDateRangeEnd={dateRangeEnd} /> <hr />
              <CheckBoxMunicipalityComponent checkedId={ReduxMunicipalities} />
            </div>
          </span>
        </Col>

        <Col xs={24} sm={24} md={19} lg={19}>
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24}>
              {events.length !== 0 && 
                <ViewEventsComponent
                array={events}
                total={events.length}
                setEventsMarkerExploration={PoisesMarkerExploration} />
              } 
            </Col>
          </Row>
        </Col>

        </Row>
      </div>
    </>
  )
}

export default Events
