import React from 'react'
import { Row, Col , Carousel} from 'antd'
import classes from "./css/Slider.module.css"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { SampleNextArrow, SamplePrevArrow } from '../../../components/ArrowsForCarousel/Slider'

const Slider: React.FC = () => {

  const { t } = useTranslation()

  const settings = {
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 5,
          infinite: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }  
  return (
    <>
      <div className={classes.container}>
        <Row justify="center">

          <Col span={20}>
            <div className={classes.title}>
              {t('Slider_Title')}
            </div>
            <div className={classes.subTitle}>
              {t('Slider_SubTitle')}
            </div>

            <div className={classes.sliderContainer}>
              <Carousel autoplay {...settings} >
                <div>
                  <img className={classes.image} alt="Chania image 1" src="./img/headerSlider/01.jpg" />
                </div>
                <div>
                  <img className={classes.image} alt="Chania image 2" src="./img/headerSlider/02.jpg" />
                </div>
                <div>
                  <img className={classes.image} alt="Chania image 3" src="./img/headerSlider/03.jpg" />
                </div>
                <div>
                  <img className={classes.image} alt="Chania image 4" src="./img/headerSlider/04.jpg" />
                </div>
              </Carousel>
            </div>

          </Col>
        </Row>
      </div>
    </>
  )
}

export default Slider
