import ky from 'ky'
import {APIEvents} from './configuration'

export const axiosEvents = {

  async getEvent(id: string) {
    const data: any = await ky.get(`${APIEvents}/el/events/${id}`).json()
    return data[0]
  },

  async getEvents() {
    const data: any  = await ky.get(`${APIEvents}/el/events/?limit=1000`).json()
    return data.events
  }, 

  async getEventsCurrent() {
    const data:any = await ky.get(`${APIEvents}/el/events/current`).json()
    return data.events
  },

  async getEventsUpcoming() {
    const data:any = await ky.get(`${APIEvents}/el/events/upcoming`).json()
    return data.events
  }

}