import React, { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { user } from "../services/user"
import { AreasProps } from "./props/areas"
import { GeneralProps } from "./props/general"
import { PoisProps, PoisProps2 } from "./props/pois"

export const useUserLogin = (email: string, password: string) => {
  
  const [User, setUser] = useState<any>(); 


  const { status, error, refetch } = useQuery(['login', email, password], () => user.loginUser(email, password), {

    refetchOnWindowFocus: false,
    enabled: false,

    onSuccess: (data) => {
    
      setUser(data.data)
      
    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { status, error, User, refetch }
}

export const useUserRegister = (username: string, email: string, password: string) => {
  
  const [Message, setMessage] = useState<string>(''); 

  const { refetch } = useQuery(['register', username, email, password], () => user.registerUser(username, email, password), {

    refetchOnWindowFocus: false,
    enabled: false,

    onSuccess: (data) => {
      setMessage(data.message)
    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { Message, refetch }
}






export const useUserFavoriteAddOrRemovePoint = (id: string, idPoint: string, removeOrAdd: number) => {

  const [Msg, setMsg] = useState<string>('');

  const { refetch } = useQuery(['UserFavoriteAddOrRemovePoint', id, idPoint, removeOrAdd], () => user.userFavoriteAddOrRemovePoint(id, idPoint, removeOrAdd), {

    refetchOnWindowFocus: false,
    enabled: false,

    onSuccess: (data) => {

      setMsg(data)

    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { Msg, refetch }
}

export const useUserFavoritePoints = (idUser: string) => {

  let poisesArray: GeneralProps[] = []

  const [Poises, setPoises] = useState<GeneralProps[]>([]);

  const { isLoading, isFetching, refetch } = useQuery(['userFavoritePoints', idUser], () => user.userFavoritePoints(idUser), {

    // refetchOnWindowFocus: false,
    // enabled: false,

    onSuccess: (data) => {

      data.map((array: PoisProps) => {
        poisesArray.push({
          id: array.id,
          image: array.main_image,
          municipality: array.extras._municipalityName.value,
          title: array.name,
          lat: array.coordinates[0].latitude,
          lng: array.coordinates[0].longitude,
          rate: array.rating !== null ? array.rating : 0,
          vote: array.votesNum !== null ? array.votesNum : 0,
          category_id: array.category_path_ids[0][1],
          category: array.category_path[0][1],
          text: array.portal_main_info,
          
        })
      })

      setPoises(poisesArray)

    },
    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, Poises, refetch }
}
