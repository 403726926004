
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Input  } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { useAreas } from '../../hooks/useAreas'
import HelmetComponent from '../../components/Helmet'
import Spinner from '../../components/PageComponents/Spinner'
import TitleAndSubtitle from '../../components/PageComponents/TitleAndSubtitle'
import { SearchOutlined } from '@ant-design/icons'

import CheckBoxEventComponent from '../../components/CheckBoxGroup/Event'
import CheckBoxDatesComponent from '../../components/CheckBoxGroup/Date'
import CheckBoxMunicipalityComponent from '../../components/CheckBoxGroup/Municiplaity'
import utils from '../../utils'

import ViewEventsComponent from './viewEvents'
import LiefletComponent from './Lieflet'

import MapSvg from '../../img/svg/map'
import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'
import { useEventsMap } from '../../hooks/useEvent'


const Search: React.FC = () => {

  const { t } = useTranslation()

  const { isLoading, Events } = useEventsMap()
  const { areas } = useAreas()

  const ReduxEvents: any[] = useSelector((state: RootState) => state.Events.EventsRedux)
  const ReduxDates: any[] = useSelector((state: RootState) => state.Events.DatesRedux)
  const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Events.MunicipalityRedux)

  const [events, setEvents] = useState<any[]>(Events);

  const [setPoisesMarkerExploration, PoisesMarkerExploration ] = useState<any[]>([]);

  const [showMap, setShowMap] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);


  const [dateRangeStartProp, dateRangeStart] = useState<string>('')
  const [dateRangeEndProp, dateRangeEnd] = useState<string>('')


  useEffect(() => {
    setEvents(Events)
    setCheck(true)
  }, [Events])


  useEffect(() => {
    let result = utils.filterEventsArray(Events, ReduxEvents, ReduxDates, ReduxMunicipalities, dateRangeStartProp, dateRangeEndProp)
    setEvents(result)
  }, [ReduxEvents, ReduxDates, ReduxMunicipalities, dateRangeStartProp, dateRangeEndProp])
  

  if (isLoading) {
    return (<Spinner />)
  }

  return (
    <>
      <HelmetComponent title='Αναζήτηση' descrition='Αναζήτηση' />
     
      <TitleAndSubtitle title={t('Αναζήτηση')} subTitle={t('')}/>

      
      <div className={classes.divSearchContainer}>
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={{span: 8}}>
            
            <div className={classes.divIntoSearchContainer}>

              <span className={classes.searchInput}>
                <Input
                  size="large"
                  placeholder="large size"
                  prefix={<SearchOutlined />} />
              </span>

              <span>
                <Button
                  type="primary" 
                  size='large'
                  className={classes.btnSearch}
                  onClick={() => setShowMap(!showMap)}>{t('Αναζήτηση')}
                </Button>
              </span>

            </div>

          </Col>
        </Row>
      </div>

      {showMap &&
        <div className={classes.MainContainer}>
          <div className={classes.container}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className={[classes.btn, classes.btnPadding].join(" ")}>
                  <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff"/>} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <LiefletComponent setPoisesMarkerExploration={setPoisesMarkerExploration} />
              </Col>
            </Row>
          </div>
        </div>
      } 
      <div className={classes.MainContainer}>
        <div className={classes.checkBoxesContainerPoint}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              {!showMap &&
                <div className={classes.btn}><Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff"/>} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;{!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button></div>
              }
            </Col>
          </Row>
        </div>
      </div>
      <div className={classes.MainContainer}>
        <Row justify="center">
        <Col xs={24} sm={24} md={5} lg={5}>
          <div className={classes.checkBoxesContainer}>
            <p className={classes.filters}>{t('Φίλτρα αναζήτησης')}</p>
            <CheckBoxEventComponent checkedId={ReduxEvents} /><hr />
            <CheckBoxDatesComponent
              checkedId={ReduxDates}
              setDateRangeStart={dateRangeStart}
              setDateRangeEnd={dateRangeEnd} /> <hr />
            <CheckBoxMunicipalityComponent checkedId={ReduxMunicipalities} />
          </div>
        </Col>

        <Col xs={24} sm={24} md={19} lg={19}>
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24}>
              {events.length !== 0 && 
                <ViewEventsComponent
                array={events}
                total={events.length}
                setEventsMarkerExploration={PoisesMarkerExploration} />
              } 
            </Col>
          </Row>
        </Col>

        </Row>
      </div>
    </>
  )
}

export default Search
