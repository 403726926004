
import React, {useState, useEffect} from "react"
import { Col, Row, Button, Rate, Pagination } from 'antd'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"
import { HeartOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useNavigate } from "react-router-dom"

import GlobusSvg from '../../img/svg/globus'
import MapSvg from '../../img/svg/map'

interface Props{
  array: any;
  total: number
  icon: any
  setEventsMarkerExploration: any
}

const ViewRoutesComponent: React.FC<Props> = ({ array, total, icon, setEventsMarkerExploration }:Props ) => {

  const { t } = useTranslation()
  const navigate = useNavigate();
  const [Routes, setRoutes] = useState<any[]>(array);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);


  useEffect(() => {

    setRoutes(array)
    setEventsMarkerExploration(currentPage * pageSize > array.length ? array.slice((currentPage - 1) * pageSize) : array.slice((currentPage - 1) * pageSize, currentPage * pageSize))

  }, [array])
  
  
  const onChange = (page: number, pageSize: number) => {

    setCurrentPage(page)
    setPageSize(pageSize)
    setRoutes(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
    window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
    setEventsMarkerExploration(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
   
  };


  return (
    <>
      <Row justify="center">
        <Col span={23}>

          {Routes.slice(0, pageSize).map((route: any, index: any) => {

            let textShort = route.description.length > 1 ? `${route.description.substring(0, 400)}` + ' ...' : ''
            let textShortMobile = route.description.length > 1 ? `${route.description.substring(0, 240)}`+ ' ...' : ''

            return (
              <div className={classes.PointContainer} key={route.id}>
                <Row>

                  <Col xs={24} sm={24} md={4} lg={4}>
                    <div className={classes.ImgPointer}><img src={route.main_image !== '' ? route.main_image : '../img/defaultPoisImage.jpg'} className={classes.PointImg} alt={route.title} onClick={()=>navigate(`/route/${route.id}`, { replace: true })}/></div>
                  </Col>

                  <Col xs={24} sm={24} md={16} lg={16}>

                    <div className={classes.PointCenter}>
                      <div className={classes.PointCenterLeft} onClick={()=>navigate(`/route/${route.id}`, { replace: true })}>
                        {route.name}
                      </div>
                      <div className={classes.PointCenterRight}>
                        <Rate value={route.rate} />
                        <div style={{ textAlign: 'right', marginTop: '-30px'}}>
                          <span className={classes.routeTypeIcon2 }>{icon}</span><span className={classes.routeTypeText2 }> {t(route.extras?._route_type.value[0])}</span>
                        </div>
                      </div>
                    </div>

                    <div className={classes.PointCenterBottomPlaceAndType}>
                      <div className={classes.PointCenterButtomLeft}>
                        <div><GlobusSvg width={25} height={25} fill="#275674" /><span className={classes.PointTextBtm}>{route.area_path[0]}</span></div>
                      </div>
                      {/* <div className={classes.PointCenterButtomRight}>
                        <div><MapSvg width={25} height={25} fill="#275674" /><span className={classes.PointTextBtm}>{route.name}</span></div>
                      </div> */}
                    </div>
                    

                    <div className={classes.PointCenter}>           
                      <div className={classes.PointCenterCenterP} dangerouslySetInnerHTML={{ __html: textShort }} />  
                      <div className={classes.PointCenterCenterPMobile} dangerouslySetInnerHTML={{ __html: textShortMobile }} />  
                    </div>

    
                    <div className={classes.PointCenterBottom}>
                      <div className={classes.PointCenterButtomLeft}>
                        <span className={classes.routeTypeIcon }>{icon}</span><span className={classes.routeTypeText }> {t(route.extras?._route_type.value[0])}</span>
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={4}>
                    <div className={classes.centerVertically}>
                      {/* <Button size='large' icon={< HeartOutlined />} className={classes.PointFavoriteBtn}>{t('Προσθήκη στα Αγαπημένα')}</Button> */}
                      <Button size='large' icon={< PlusCircleOutlined />} className={classes.PointMoreBtn} onClick={()=>navigate(`/route/${route.id}`, { replace: true })}>{t('Περισσότερα')}</Button>
                    </div>
                  </Col>

                </Row>
              </div>
            )
          })}
        </Col>
      </Row>
      
      <Row justify="center">
        <Col span={23}>
          <Pagination
            onChange={onChange}
            className={classes.pagination}
            total={total}
            showSizeChanger ={true}
            showTotal={(total, range) =>  t('Αποτελέσματα') +' '+ `${range[0]} - ${range[1]}`+ ' ' + t('από') +' '+ `${total}`}
            defaultPageSize={5}
            defaultCurrent={1}
          />
        </Col>
      </Row>

    </>
  )
}

export default ViewRoutesComponent