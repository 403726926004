
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Spin, Rate, message, Popconfirm  } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { usePois } from '../../../hooks/useExploration'
import { useParams, useNavigate } from 'react-router-dom'
import { HeartOutlined, ExclamationOutlined } from '@ant-design/icons'

import { useUserFavoriteAddOrRemovePoint } from '../../../hooks/useUser'
import { useAuth } from "../../../utils/auth"

import MapSvg from '../../../img/svg/map'

import HelmetComponent from '../../../components/Helmet'
import LiefletComponent from './Lieflet'
import SliderComponent from './Slider'

import PlayComponent from './Play'
import ThreePoisesComponent from './ThreePoises'
import { t } from "i18next"


const ExplorationView: React.FC = () => {

  const { t } = useTranslation()
  const auth = useAuth()


  const { id } = useParams()
  const { isLoading, isFetching,  PoisData } = usePois(id || '')
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage()

  const [pois, setPois] = useState<any>({})
  const [poisId, setPoisId] = useState<string>('')
  const { refetch } = useUserFavoriteAddOrRemovePoint(auth.userData.id, poisId, 1)
  const [showMap, setShowMap] = useState<boolean>(false)

  const onClickAdd = (poisId: string) => {

    setPoisId(poisId)

    messageApi.open({
          type: 'success',
          content: t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'),
          style: {
            marginTop: '20vh'
          },
        })
  }

  useEffect(() => {
    if (poisId !== '')
    refetch()
 }, [poisId]);


  useEffect(() => {
    if(PoisData)
    setPois(PoisData)
  }, [PoisData])


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  if (isLoading || Object.keys(pois).length < 0) {
    return (
      <Row justify="center" >
        <Col span={12} offset={6}>
          <div className={classes.loadingSpinner}> <Spin size="large" /> {t('Παρακαλώ περιμένετε')} ... </div>
        </Col>
      </Row>
    )
  }

  return (
    <>
      {contextHolder}
      <HelmetComponent title='Εξερεύνησε τις ομορφιές των Χανίων' descrition='Εξερεύνησε τις ομορφιές των Χανίων' />
      {isFetching && <div className={classes.allScreen}>
          <div className={classes.loader}></div>
        </div>}
      <div className={classes.container}>
        <Row justify="center">
          <Col span={14}>
            <div className={classes.title}>
              {pois.title}
            </div>
            <div className={classes.subTitle}>
              {t('Δήμος') + ' ' + pois.municipality}
            </div>
            
          </Col>
        </Row>

        <Row justify="center">
          <Col span={22}>
            <Button size='large' className={classes.BackBtn} onClick={() => navigate(-1)}>{t('Επιστροφή')}</Button>
          </Col>
        </Row>

      </div>

      {showMap &&
        <>  
        <div className={classes.container}>
          <Row justify="center">
            <Col xs={24} sm={24} md={{ span: 16 }} lg={{ span: 16 }}>
              <div className={[classes.btn, classes.btnPadding].join(" ")}>
                <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={{ span: 16 }} lg={{ span: 16 }}>
              <LiefletComponent
                image={pois.image}
                title={pois.title}
                latitude={pois.lat}
                longitude={pois.lng}
              />
            </Col>
          </Row>
        </div>
        </>
      } 

      <Row justify="center">
        <Col xs={16} sm={16} md={{ span: 16 }} lg={{ span: 16 }}>
          <div className={classes.rate}>
            <Rate value={pois.rating}  />
          </div>
        </Col>
      </Row>


      <Row justify="center">
        <Col xs={16} sm={16} md={{ span: 16 }} lg={{ span: 16 }}>
          <div className={classes.PointFavoriteBtnRight}>
            {(auth.userData.id == '' || auth.userData.id == undefined)
              ?
              <Popconfirm
                title={t('Παρακαλώ συνδεθείτε')}
                //  description="Πρέπει να είστε συνδεδεμένοι για να προσθέσετε σημεία ενδιαφέροντος στη λίστα με τα Αγαπημένα σας."
                onConfirm={() => navigate('/login')}
                icon={<ExclamationOutlined style={{ color: '#275674', fontWeight: '600' }} />}
                okText={t('Σύνδεση')}
                showCancel={false}
              >
                <Button
                  size='large'
                  icon={< HeartOutlined />}
                  className={classes.PointFavoriteBtn}
                >
                  {t('Προσθήκη στα Αγαπημένα')}
                </Button>
              </Popconfirm>
              :
              <Button
                size='large'
                icon={< HeartOutlined />}
                className={classes.PointFavoriteBtn}
                onClick={() => auth.userData.id !== '' && onClickAdd(pois.id)}
              >
                {t('Προσθήκη στα Αγαπημένα')}
              </Button>
            }
          </div>
        </Col>
      </Row>

      <PlayComponent mp3={pois.mp3 } />

      <div className={classes.checkBoxesContainerPoint}>
        <Row justify="center">
          <Col xs={24} sm={24} md={{ span: 16 }} lg={{ span: 16 }} >
            {!showMap &&
              <div className={classes.btn}><Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button></div>
            }
          </Col>
        </Row>
      </div>

      
      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <div className={classes.description}>
           
            <div className={classes.eventTitle}>{t('Περιγραφή')}</div>

            <div className={classes.eventDescription} dangerouslySetInnerHTML={{ __html: pois.description }} />

            {pois.images && pois.images.length !== 0 &&
              <>
                <div className={classes.eventPhoto}>{t('Πολυμεσικό Υλικό Σημείου')}</div>
                <SliderComponent images={pois.images} />
              </>
            }
 
         </div>
        </Col>
      </Row> 

      
      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <br/><br/>
          <div className={classes.eventTitle}>{t('Κατηγορίες')}</div>
          {/* <div>{ pois?.category && pois.category.forEach((cat: string) => <div> {cat}</div> )}</div>*/}
          <div>{pois?.category && pois.category.map((cat: string, index: number) => <span key={ index } className={classes.Categories}>{cat}</span>) }</div>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={14}>
          <div className={classes.hr}><hr/></div>
        </Col>
      </Row> 

      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <div className={classes.eventTitle}>{t('Λέξεις κλειδιά')}</div>
          <div>{pois?.tags && pois.tags.map((tag: string, index: number) => <div key={ index } className={classes.Categories}>{tag}</div>) }</div>
        </Col>
      </Row>
      <br/>

      <div style={{ backgroundColor: '#f3f8fd' }}>
        <Row justify="center">
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }} >
            <ThreePoisesComponent />
          </Col>
        </Row>
      </div>

    </>
  )
}

export default ExplorationView
