import ky from 'ky'
import {API, Header} from './configuration'

export const axiosExplorations = {

  async getCategories() {
    const data: any = await ky.get(`${API}/categoriesTree`, {headers: Header}).json()
    return data.success
  },

  async getPois(id: string) {
    const data: any = await ky.get(`${API}/poi?id=${id}&fetch_files=1`, {headers: Header}).json()
    return data.success;
  },

  async getPoises() {
    const data: any = await ky.get(`${API}/pois?fetch_files=1`, {headers: Header}).json()
    return data.success;
  }

}