import React from "react"
import { Checkbox, Col, Row, Collapse } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"


// Redux
import { useDispatch } from 'react-redux'
import { History, Environment, Municipality }  from '../../redux/mapPage/category'

const { Panel } = Collapse;

interface Props{
  checkedId: number;
  icon?: any;
  header?: string;
  array: any;
  type: string;
}

const CheckBoxComponent: React.FC<Props> = ({ checkedId, icon, header, array, type }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()


  const onChange = (list: CheckboxValueType[]) => {
    
    type === 'history' ? dispatch(History(list)) :
      type === 'environment' ? dispatch(Environment(list)) : dispatch(Municipality(list))
  }


  if (type === 'municipality') {
    return (
      <>
        {header && <div className={classes.divider}>{icon}&nbsp;&nbsp;{header}</div>}
        <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
          <Row>
              <Col span={24} className={classes.checkboxGroup}>
                <Checkbox value={t('Αποκορώνου')}>{t('Αποκορώνου')}</Checkbox>
              </Col>
              <Col span={24} className={classes.checkboxGroup}>
                <Checkbox value={t('Γαύδου')}>{t('Γαύδου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
                <Checkbox value={t('Καντάνου - Σελίνου')}>{t('Καντάνου - Σελίνου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
                <Checkbox value={t('Κισσάμου')}>{t('Κισσάμου')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
                <Checkbox value={t('Πλατανιά')}>{t('Πλατανιά')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
                <Checkbox value={t('Σφακίων')}>{t('Σφακίων')}</Checkbox>
            </Col>
            <Col span={24} className={classes.checkboxGroup}>
                <Checkbox value={t('Χανίων')}>{t('Χανίων')}</Checkbox>
            </Col>
        </Row>
      </Checkbox.Group>
    </>
  )
  }


  return (
    <>
      {header && <div className={classes.divider}>{icon}&nbsp;&nbsp;{header}</div>}
      
      <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={[checkedId]}>
        <Row>

          {array.slice(0, 6).map((item: any, index: any) => {

            return (
              <Col span={24} key={item.id} className={classes.checkboxGroup}>
                <Checkbox value={item.id}>{item.name}</Checkbox>
              </Col>
            )
          })}

          {type !== 'municipality' &&

            <Collapse ghost expandIconPosition='start' >
              <Panel header={t("Εμφάνιση όλων") + ' (' + array.length + ')'} key="1" className={classes.collapse} >

                {array.slice(6, array.length).map((item: any, index: any) => {

                  return (
                    <Col span={24} key={item.id} className={classes.checkboxGroup}>
                      <Checkbox value={item.id}>{item.name}</Checkbox>
                    </Col>
                  )
                })}

              </Panel>
            </Collapse>
          }

        </Row>
      </Checkbox.Group>



      {/* <CheckboxGroup
        options={array.map((item: any) => ({ label: item.name, value: item.id }))}
        value={checkedList}
        onChange={onChange}
        className={classes.checkboxGroup}
      /> */}
    </>
  )
}

export default CheckBoxComponent