import React, { useState, useEffect } from 'react'
import { CalendarOutlined, NodeIndexOutlined, SearchOutlined, MenuOutlined } from "@ant-design/icons"
import { Button, Select } from 'antd';
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import classes from "./Navbar.module.css";
import { UsefulSvg } from './svg'
import { NavHashLink } from 'react-router-hash-link'

import CompassSvg from '../../img/svg/compass'

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { t } = useTranslation()

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }


  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])

    return (
      <div>
        {(toggleMenu || screenWidth > 1280) && (
          <ul className={ [classes.list, classes.items].join(" ")}>
            <Link to="/explorations" state={{ page:'exploration' }} onClick={toggleNav} ><CompassSvg width={24} height={24} fill="#1b7093" /><span className={classes.compass}>{t('Εξερεύνηση')}</span> </Link>
            <Link to="/routes" state={{ page:'routes' }} onClick={toggleNav} ><NodeIndexOutlined /> {t('Διαδρομές')}</Link>
            <Link to="/events" state={{ page: 'events' }} onClick={toggleNav} ><CalendarOutlined /> {t('Εκδηλώσεις')}</Link>
            <Link to="/useful" state={{ page: 'useful' }} onClick={toggleNav} ><UsefulSvg /> {t('Χρήσιμα')}</Link>
            {/* <Link to="/page/funding_programs" state={{ page: 'funding_programs' }} onClick={toggleNav} ><NodeIndexOutlined /> {t('Χρήσιμα')}</Link> */}

            {/* <span className={classes.sima}><UsefulSvg /></span>
            <span className={classes.sima2}> */}

             {/* <Link to="/useful" state={{ page:'events' }} onClick={toggleNav} > 
            <Select
              size='large'
              showArrow={false}
              defaultValue={t('Χρήσιμα')}
              style={{ width: 290, marginLeft: '-16px', textAlign: 'left'}}
              bordered={false}
              onChange={handleChange}
              options={[
                {
                  value: t('Χρήσιμα'),
                  label: <span className={classes.useful}>{t('Χρήσιμα')}</span>
                },
                {
                  value: t('Τουριστικοί Χάρτες'),
                  label: <span className={classes.useful}><NavHashLink smooth to="/useful#TouristMaps">{t('Τουριστικοί Χάρτες')}</NavHashLink></span> 
                },
                {
                  value: t('Χρήσιμες Πληροφορίες'),
                  label: <span className={classes.useful}><NavHashLink smooth to="/useful#UsefulInfo">{t('Χρήσιμες Πληροφορίες')}</NavHashLink></span>,
                },
                {
                  value: t('Πρόγνωση Καιρού'),
                  label: <span className={classes.useful}><NavHashLink to="/useful#WeatherForecast">{t('Πρόγνωση Καιρού')}</NavHashLink></span>,
                },
                {
                  value: t('Live Κάμερες'),
                  label:(<> <span className={classes.useful}><NavHashLink to="/useful#LiveCams">{t('Live Κάμερες')}</NavHashLink></span></>),
                },
                {
                  value: 'COVID 19',
                  label: <span className={classes.useful}><NavHashLink to="/useful#Covid19">COVID 19</NavHashLink></span>,
                }
              ]}
            />
            </Link>  */}
            {/* </span> */}
            <span className={classes.searchButton} ><Button size="large" icon={<SearchOutlined />} style={{ color: '#275674'}}><span></span>Search</Button></span>
          </ul>
        )}
        <button onClick={toggleNav} className={classes.btn}><MenuOutlined /></button>
      </div>
    )

}
export default Navbar
