import React from "react"
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classes from "./Mobile.module.css"

function Mobile() {

  const { t } = useTranslation()

  return (
    <>
      <div className={classes.footerContainer}>
        <Row justify="space-around">
          <Col xs={24} sm={24} md={14} lg={14} >
            <div className={classes.footerDiv}>
              <p className={classes.explore}><span className={classes.exploreText}>Explore Chania App</span></p>
              <p className={classes.title}>{t('Εξερεύνησε τα Χανιά,αλλιώς.')}</p>
              <p className={classes.body}>{t('Η εφαρμογή είναι ειδικά σχεδιασμένη για να προσφορεί πλήρες περιεχόμενο, δυνατότητες επαυξημένης πραγματικότητας που θα σε βοηθήσουν να δεις τα Χανιά, όπως δεν τα έχεις ξαναδεί.')}</p>
              <p className={classes.image}>
                <img src="../img/iphone.png" alt="Iphone"  />
                <img src="../img/android.png" alt="Android" className={classes.margin} />
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} >
            <div className={classes.footerDivRight}>
              {/* <img className={classes.image1} src="../img/Blob.png" alt="blob" /> */}
              <img className={classes.image2} src="../img/mobile.png" alt="Mobile"  />
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Mobile